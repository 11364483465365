// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.353 4.05398C12.5953 3.60793 12.5512 3.15086 12.353 3.0242C12.2814 2.98566 10.6128 2.02747 10.3981 1.92284C10.3556 1.90065 10.3084 1.88906 10.2604 1.88906C10.2125 1.88906 10.1652 1.90065 10.1227 1.92284L8.32752 2.61119L7.73279 1.35014C7.70711 1.28687 7.66044 1.23437 7.60063 1.20146C7.35282 1.06379 5.69527 0.100093 5.64571 0.0670522C5.3979 -0.0706177 4.9849 0.237765 4.8252 0.766418L3.60269 4.76986C3.53423 5.01004 3.39628 5.22462 3.2062 5.38662L0.452802 7.49022C0.0177647 7.82613 -0.152946 8.63563 0.160941 8.86141L2.13788 10.2877C2.21058 10.3571 2.26287 10.4452 2.28908 10.5423C2.31529 10.6393 2.31442 10.7417 2.28657 10.8384L1.82399 14.7317C1.78545 15.018 1.86254 15.2107 1.99471 15.2823L3.95512 16.4167C4.10931 16.5048 4.3406 16.4167 4.54986 16.1689L6.98937 13.0466C7.04234 12.962 7.1152 12.8917 7.20159 12.8418C7.28798 12.7919 7.38527 12.7639 7.48498 12.7602L9.92449 13.0631C10.3155 13.1127 10.767 12.4078 10.6954 11.8736L10.2274 8.51999C10.2055 8.27304 10.2593 8.02529 10.3816 7.80961L12.353 4.05398Z"
      fill="#D0C137"
    />
    <path
      opacity="0.15"
      d="M12.3309 3.02412C12.2886 3.00479 12.2426 2.99478 12.196 2.99478C12.1495 2.99478 12.1035 3.00479 12.0611 3.02412L9.30771 4.07041C9.27189 4.08845 9.23268 4.09875 9.19262 4.10067C9.15256 4.10258 9.11254 4.09606 9.07517 4.08152C9.03779 4.06698 9.00388 4.04476 8.97564 4.01629C8.9474 3.98781 8.92545 3.95372 8.91122 3.91622L8.28345 2.57807L10.0787 1.89523C10.1202 1.87305 10.1665 1.86145 10.2136 1.86145C10.2606 1.86145 10.307 1.87305 10.3485 1.89523L12.3309 3.02412Z"
      fill="#F2E24D"
    />
    <path
      opacity="0.15"
      d="M5.54665 5.86596C5.47416 6.1072 5.33477 6.32296 5.14465 6.48822L2.39126 8.59182C2.26666 8.69049 2.16674 8.81681 2.0994 8.96077L0.138977 7.82637C0.208556 7.6838 0.308114 7.55795 0.430838 7.45742L3.18424 5.35382C3.37441 5.19087 3.51391 4.97683 3.58623 4.73706L5.54665 5.86596Z"
      fill="#F2E24D"
    />
    <path
      opacity="0.1"
      d="M7.61722 1.17415C7.36942 1.01445 6.9509 1.32834 6.7857 1.868L5.56869 5.86594L3.60828 4.73154L4.82528 0.733606C4.98498 0.182926 5.37596 -0.10343 5.64579 0.03424C5.69535 0.0672807 7.3529 1.03097 7.60621 1.1356C7.58418 1.16864 7.61722 1.16864 7.61722 1.17415Z"
      fill="#F2E24D"
    />
    <path
      opacity="0.25"
      d="M4.09274 11.3947L2.15435 10.2933L0.160885 8.82853C-0.0648937 8.66333 -0.0373597 8.20076 0.160885 7.82629L2.11029 8.96069C1.91205 9.32965 1.88451 9.79773 2.11029 9.96293L4.09274 11.3947Z"
      fill="#F2E24D"
    />
    <path
      opacity="0.15"
      d="M3.76238 15.8223C3.72934 16.1142 3.81194 16.3069 3.95512 16.373L1.9947 15.2386C1.86254 15.1615 1.78544 14.9743 1.81848 14.6879L2.29207 10.7946C2.30992 10.7035 2.3068 10.6096 2.28295 10.5199C2.2591 10.4302 2.21514 10.3471 2.1544 10.277L4.09279 11.3783C4.15425 11.4481 4.19868 11.5311 4.22257 11.6209C4.24646 11.7108 4.24916 11.8049 4.23046 11.896L3.76238 15.8223Z"
      fill="#F2E24D"
    />
    <path
      d="M7.71076 1.31755L8.93327 3.91125C8.94763 3.9487 8.96956 3.98279 8.99768 4.01139C9.02581 4.03998 9.05952 4.06248 9.09673 4.07746C9.13393 4.09245 9.17383 4.0996 9.21392 4.09848C9.25401 4.09736 9.29345 4.08799 9.32976 4.07095L12.0832 3.01915C12.5182 2.85395 12.6889 3.46521 12.375 4.05443L10.3705 7.76601C10.2483 7.98169 10.1945 8.22944 10.2164 8.47639L10.6844 11.83C10.756 12.3807 10.3045 13.0691 9.91348 13.0195L7.47397 12.7166C7.37426 12.7203 7.27696 12.7483 7.19057 12.7982C7.10418 12.8481 7.03132 12.9184 6.97835 13.003L4.53884 16.1253C4.14786 16.6209 3.6963 16.4392 3.7734 15.8225L4.23597 11.9292C4.26382 11.8325 4.26469 11.7301 4.23848 11.6331C4.21227 11.536 4.15998 11.4479 4.08728 11.3785L2.11034 9.96322C1.79646 9.73744 1.96717 8.92795 2.4022 8.59203L5.1556 6.48844C5.34568 6.32644 5.48363 6.11186 5.55209 5.87167L6.7746 1.86823C6.95633 1.22945 7.51802 0.904544 7.71076 1.31755Z"
      fill="#BA68C8"
    />
    <path
      d="M7.71076 1.31755L8.93327 3.91125C8.94763 3.9487 8.96956 3.98279 8.99768 4.01139C9.02581 4.03998 9.05952 4.06248 9.09673 4.07746C9.13393 4.09245 9.17383 4.0996 9.21392 4.09848C9.25401 4.09736 9.29345 4.08799 9.32976 4.07095L12.0832 3.01915C12.5182 2.85395 12.6889 3.46521 12.375 4.05443L10.3705 7.76601C10.2483 7.98169 10.1945 8.22944 10.2164 8.47639L10.6844 11.83C10.756 12.3807 10.3045 13.0691 9.91348 13.0195L7.47397 12.7166C7.37426 12.7203 7.27696 12.7483 7.19057 12.7982C7.10418 12.8481 7.03132 12.9184 6.97835 13.003L4.53884 16.1253C4.14786 16.6209 3.6963 16.4392 3.7734 15.8225L4.23597 11.9292C4.26382 11.8325 4.26469 11.7301 4.23848 11.6331C4.21227 11.536 4.15998 11.4479 4.08728 11.3785L2.11034 9.96322C1.79646 9.73744 1.96717 8.92795 2.4022 8.59203L5.1556 6.48844C5.34568 6.32644 5.48363 6.11186 5.55209 5.87167L6.7746 1.86823C6.95633 1.22945 7.51802 0.904544 7.71076 1.31755Z"
      fill="#F2E24D"
    />
    <path
      opacity="0.15"
      d="M16.0351 1.18331C16.0227 1.17765 16.0093 1.17472 15.9956 1.17472C15.982 1.17472 15.9685 1.17765 15.9561 1.18331L15.1496 1.48977C15.1393 1.49503 15.1279 1.49804 15.1163 1.49859C15.1047 1.49913 15.0931 1.49721 15.0822 1.49294C15.0714 1.48867 15.0616 1.48216 15.0535 1.47383C15.0454 1.46549 15.0391 1.45553 15.0351 1.4446L14.8513 1.05266L15.3819 0.860723C15.3941 0.854228 15.4076 0.85083 15.4214 0.85083C15.4352 0.85083 15.4488 0.854228 15.4609 0.860723L16.0351 1.18331Z"
      fill="white"
    />
  </svg>
);
