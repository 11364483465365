import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NetPromoterScoreAPIResponse } from '../../models';
import { apiNetPromoterScore } from '../../modules/api';
import NetPromoterScoreContext, {
  netPromoterScoreDefaultValue,
} from './NetPromoterScore.context';

const NetPromoterScoreProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const [netPromoterScore, setNetPromoterScore] =
    useState<NetPromoterScoreAPIResponse | null>(
      netPromoterScoreDefaultValue.netPromoterScore
    );

  const [discardNetPromoterScore, setDiscardNetPromoterScore] = useState(false);

  const getNetPromoterScore = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await apiNetPromoterScore.getNetPromoterScore();

      setNetPromoterScore(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getNetPromoterScore();
  }, [getNetPromoterScore]);

  useEffect(() => {
    const netPromoterScoresIsDiscarded = !!localStorage.getItem(
      'discardNetPromoterScore'
    );

    setDiscardNetPromoterScore(netPromoterScoresIsDiscarded);

    if (!netPromoterScoresIsDiscarded) {
      localStorage.removeItem('discardNetPromoterScore');
    }
  }, []);

  const showNetPromoterScorePopup = useMemo(() => {
    return (
      !!netPromoterScore &&
      !netPromoterScore?.show_after &&
      !netPromoterScore?.user_finished_survey &&
      !discardNetPromoterScore
    );
  }, [discardNetPromoterScore, netPromoterScore]);

  return (
    <NetPromoterScoreContext.Provider
      value={{
        netPromoterScore,
        setNetPromoterScore,
        showNetPromoterScorePopup,
        discardNetPromoterScore,
        setDiscardNetPromoterScore,
        getNetPromoterScore,
      }}
    >
      {children}
    </NetPromoterScoreContext.Provider>
  );
};

export default NetPromoterScoreProvider;
