export const weekDays = [
  'global-day_mon',
  'global-day_tue',
  'global-day_wed',
  'global-day_thu',
  'global-day_fri',
  'global-day_sat',
  'global-day_sun',
];

export const calendarDateFormat = 'DD-MMM-YYYY';

export const ALLOWED_PARKING_SPACES_PER_CARPARK = 1000;
