import { useContext, useMemo, useCallback, FC, memo, useRef } from 'react';
import { Button, Heading } from '@faxi/web-component-library';
import {
  FormField,
  Form,
  DataState,
  validators,
  FormRef,
  validationRegexes,
} from '@faxi/web-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
  Icon,
  ErrorMessage,
  PasswordField,
  InputField,
  Microsoft,
} from 'components';
import Styled from 'components/_layouts/Containers';
import { AuthContext } from 'store';
import { appUri } from 'config';
import { useHeadTitle, useLoginRedirections } from 'hooks';

import Apple from 'components/authProviders/Apple';
import Google from 'components/authProviders/Google';

export const FormMemoized = memo(Form);

const LoginForm: FC = (): JSX.Element => {
  const { failedAttempts, errorMessage, placeholderValue, handleLogin } =
    useContext(AuthContext);

  const formRef = useRef<FormRef>(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  useHeadTitle(t('login'));

  const validations = useMemo(
    () => ({
      email: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_email_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.workEmail,
          t('validation-field_valid_email', {
            fieldname: t('register_email_hint').toLowerCase(),
          })
        ),
      ],
      password: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_password_hint'),
          })
        ),
      ],
    }),
    [t]
  );

  const minutes = useMemo(
    () =>
      Number(placeholderValue) > 1
        ? t('minutes_plural.other', { returnObjects: true })
        : t('minutes_plural.one', { returnObjects: true }),
    [placeholderValue, t]
  );

  const lockedMessage = useMemo(
    () =>
      t('error_1040', {
        PLACEHOLDER_VALUE: placeholderValue + ' ' + minutes,
        interpolation: { prefix: '%%', suffix: '%%' },
      }),
    [minutes, placeholderValue, t]
  );

  const attemptsMessage = useMemo(
    () =>
      t('error_1038', {
        PLACEHOLDER_VALUE: placeholderValue,
        interpolation: { prefix: '%%', suffix: '%%' },
      }),
    [placeholderValue, t]
  );

  const handleLoginUser = useCallback(
    async (event: DataState) => {
      if (!formRef.current) return;

      const formData = new FormData();
      Object.entries(event).forEach(([key, value]) =>
        formData.append(key, value)
      );
      formRef.current.clearAsyncErrors();
      handleLogin(formData, formRef.current);
    },
    [handleLogin]
  );

  const failedAttemptsMessage = useCallback((): string => {
    switch (failedAttempts) {
      case '1038':
        return attemptsMessage;
      case '1039':
        return t('error_1039');
      case '1040':
        return lockedMessage;
      default:
        return '';
    }
  }, [attemptsMessage, failedAttempts, lockedMessage, t]);

  useLoginRedirections();

  return (
    <Styled.AuthGrid className="login-form">
      <FormMemoized
        id="login_form"
        ref={formRef}
        className="form"
        onSubmit={handleLoginUser}
        strictValidation={false}
      >
        <div className="form__fields">
          {errorMessage && <ErrorMessage text={t('verify_your_email')} />}
          {failedAttempts && <ErrorMessage text={failedAttemptsMessage()} />}
          <Heading level="1">{t('login')}</Heading>
          <FormField
            className="form__fields__field"
            component={InputField}
            prefixIcon={<Icon name="envelope" />}
            name="login"
            id="login_email"
            autoComplete="on"
            label={t('register_email_hint')}
            placeholder={t('enter_email')}
            validate={validations.email}
            required
            requiredLabel={t('global-input_field_required_label')}
            onChange={() => {
              if (errorMessage || failedAttempts) {
                formRef.current?.clearAsyncErrors();
                formRef.current?.reportFieldValidity('login', true);
                formRef.current?.reportFieldValidity('password', true);
              }
            }}
          />
          <FormField
            className="form__fields__field"
            component={PasswordField}
            prefixIcon={<Icon name="lock-keyhole" />}
            name="password"
            id="login_password"
            label={t('Password')}
            placeholder={t('enter_password')}
            validate={validations.password}
            required
            requiredLabel={t('global-input_field_required_label')}
            onChange={() => {
              if (errorMessage || failedAttempts) {
                formRef.current?.clearAsyncErrors();
                formRef.current?.reportFieldValidity('login', true);
                formRef.current?.reportFieldValidity('password', true);
              }
            }}
          />
          <div className="form__fields__commands">
            <div className="form__fields__commands--standard">
              <Button id="submit_login" type="submit" className="login-btn">
                {t('login')}
              </Button>
              <Button
                id="go_to_reset_password"
                type="button"
                variant="ghost"
                className="reset-password-btn"
                onClick={() => navigate(appUri.LOGIN_RESET_PASSWORD)}
              >
                {t('Forgotten_password_webapp')}
              </Button>
            </div>

            <div className="form__fields__commands--or">
              <span>{t('or')}</span>
            </div>

            <div className="form__fields__commands--social">
              <Apple />
              <Google />
              <Microsoft />
            </div>

            <span className="form__redirect-link">
              <Trans
                i18nKey={t('signup-body_new_to_kinto_join')
                  .replace(/{/g, '<a>$t(')
                  .replace(/}/g, ')</a>')}
                t={t}
                components={{ a: <Link to="/sign-up"></Link> }}
              />
            </span>

            <span className="form__redirect-link">
              <Link to="/login-sso"> {t('login-log_in_with_sso')} </Link>
            </span>
          </div>
        </div>
      </FormMemoized>
    </Styled.AuthGrid>
  );
};
export default LoginForm;
