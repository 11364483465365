import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  Breadcrumbs,
  Button,
  Checkbox,
  Heading,
  Icon,
  useCallbackRef,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import { UserContext } from 'store';
import {
  FormActions,
  GoogleAutocompleteField,
  InputField,
  TextareaField,
} from 'components';
import { apiParkingManagement } from 'modules';
import { CreateCarparkFormValuesType } from 'models';
import specific from '../../../validation/validators/specific';
import numberValidation from '../../../validation/validators/numberValidation';

import * as Styled from './CreateCarpark.styles';
import { ALLOWED_PARKING_SPACES_PER_CARPARK } from '../constants';

const CreateCarpark = () => {
  const { communityId, user } = useContext(UserContext);

  const { t } = useTranslation();

  const [form, formRef] = useCallbackRef<FormRef>();

  const [enableParkingSpaces, setEnableParkingSpaces] = useState(false);

  const navigate = useNavigate();

  const { showOverlay, hideOverlay } = useUtilities();

  const [FormButtons] = useFormButtons({
    submitLabel: t('global_button_create'),
    cancelLabel: t('cancel'),
  });

  const numberOfParkingSpaces = useFormRefValues(
    form,
    'numberOfParkingSpaces'
  )?.numberOfParkingSpaces;

  const [debouncedValue, setDebouncedValue] = useState(numberOfParkingSpaces);

  const [parkingSpaces, setParkingSpaces] = useState<string[]>([]);

  const [namesErrors, setNamesErrors] = useState<string[]>([]);

  const handleSubmit = useCallback(
    async (values: CreateCarparkFormValuesType) => {
      const {
        name,
        description,
        numberOfParkingSpaces,
        address: { formatted_address, lat, lng },
      } = values;

      showOverlay('#main');

      const spots = enableParkingSpaces
        ? parkingSpaces?.map((carspace) => ({
            name: carspace,
            status: 'active',
            lat,
            lng,
          }))
        : undefined;

      try {
        const {
          data: { data },
        } = await apiParkingManagement.createParking({
          name: name,
          description: description,
          capacity: +numberOfParkingSpaces,
          address: formatted_address!,
          lat: lat,
          lng: lng,
          status: 'active',
          organisation_id: communityId!,
          creator_id: user?.id!,
          spots,
        });
        if (data) {
          navigate(
            `/community/${communityId}/admin/parking-management/${data.id}`
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('#main');
      }
    },
    [
      showOverlay,
      enableParkingSpaces,
      parkingSpaces,
      communityId,
      user?.id,
      navigate,
      hideOverlay,
    ]
  );

  const handleSwitch = useCallback(() => {
    setEnableParkingSpaces((old) => !old);
  }, []);

  const updateDebouncedValue = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedValue(value);
      }, 500),
    []
  );

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('global-name'),
          })
        ),
        validators.general.maxLength(
          30,
          t('validation_max_length', {
            value: '30',
          })
        ),
      ],
      description: [
        validators.general.maxLength(
          300,
          t('validation_max_length', {
            value: '300',
          })
        ),
      ],
      address: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('address_header'),
          })
        ),
      ],
      numberOfParkingSpaces: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('parking_management-number_of_parking_spaces'),
          })
        ),
        specific.positiveNumbersOnly(
          t('validation-field_positive_round_number_only', {
            fieldname: t(
              'parking_management-number_of_parking_spaces'
            ).toLowerCase(),
          })
        ),
        numberValidation.maxValue(
          t('validation-field_validation_max_value', {
            fieldname: t(
              'parking_management-number_of_parking_spaces'
            ).toLowerCase(),
            number: ALLOWED_PARKING_SPACES_PER_CARPARK,
          }),
          ALLOWED_PARKING_SPACES_PER_CARPARK
        ),
      ],
    }),
    [t]
  );

  const renderCarspaceNames = useMemo(
    () =>
      parkingSpaces.map((el, index) => (
        <InputField
          required
          placeholder={t('parking_management-parking_space_name')}
          key={index}
          value={el}
          onChange={(value) => {
            setParkingSpaces((old) => {
              const updatedItems = [...old];
              updatedItems[index] = value;
              return updatedItems;
            });
          }}
          errorText={namesErrors?.[index]}
          externalError={!!namesErrors?.[index]}
          displayError
          className="kinto-create-carpark__parking-spaces__space"
        />
      )),
    [parkingSpaces, t, namesErrors]
  );

  const validParkingSpotNumber = useMemo(() => {
    return (
      enableParkingSpaces &&
      debouncedValue &&
      debouncedValue <= ALLOWED_PARKING_SPACES_PER_CARPARK
    );
  }, [debouncedValue, enableParkingSpaces]);

  const breadcrumbs = useMemo(
    () => [
      {
        text: t('global-parking_management'),
        href: `/community/${communityId}/admin/parking-management`,
        id: 'people_bdc_back_to_community',
      },
      {
        id: 'user-profile',
        text: t('parking_management-create_carpark'),
        href: '',
      },
    ],
    [communityId, t]
  );

  useEffect(() => {
    updateDebouncedValue(numberOfParkingSpaces);
  }, [numberOfParkingSpaces, updateDebouncedValue]);

  useEffect(() => {
    if (!validParkingSpotNumber) return;
    setParkingSpaces(
      Array.from({ length: debouncedValue }, (_, index) => `${index + 1}`)
    );
    setNamesErrors(Array.from({ length: debouncedValue }, () => ''));
  }, [debouncedValue, enableParkingSpaces, validParkingSpotNumber]);

  // Parking space name validation
  useEffect(() => {
    if (!validParkingSpotNumber) return;

    const nameErrors = Array.from({ length: parkingSpaces.length }, () => '');

    parkingSpaces.forEach((space, index) => {
      const indexOfFirstValue = parkingSpaces.indexOf(space);

      const indexOfSecondValue = parkingSpaces.indexOf(
        space,
        indexOfFirstValue + 1
      );

      if (!space) {
        nameErrors[index] = t('validation-field_is_required', {
          fieldname: t('parking_management-parking_space_name'),
        });
      } else if (space.length > 4) {
        nameErrors[index] = t('validation_max_length', {
          value: '4',
        });
      } else if (
        indexOfFirstValue !== -1 &&
        indexOfSecondValue !== -1 &&
        indexOfFirstValue !== indexOfSecondValue
      ) {
        nameErrors[index] = t('validation-unique_name', {
          fieldname: t(
            'carpark_settings-placeholder_parking_space_name'
          ).toLowerCase(),
        });
      } else {
        nameErrors[index] = '';
      }

      setNamesErrors(nameErrors);
    });
  }, [parkingSpaces, t, validParkingSpotNumber]);

  return (
    <Styled.ParkingManagement className="kinto-create-carpark">
      <Breadcrumbs crumbs={breadcrumbs} />

      <div className="kinto-create-carpark__header">
        <Heading level="1" className="kinto-create-carpark__heading">
          {t('parking_management-create_carpark')}
        </Heading>
        <Button
          variant="ghost"
          onClick={(e) => {
            window.viewChangesBtnRef = e.target as HTMLElement;
            navigate(`/community/${communityId}/admin/parking-management`);
          }}
          icon={<Icon name="xmark" />}
        />
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <fieldset className="kinto-create-carpark__carpark-details">
          <legend data-hidden>{t('global-name')}</legend>
          <FormField
            required
            name="name"
            component={InputField}
            autoComplete="off"
            placeholder={t('global-name')}
            validate={validations.name}
          />
          <FormField
            name="description"
            component={TextareaField}
            autoComplete="off"
            placeholder={t('dw_description')}
            validate={validations.description}
          />
        </fieldset>

        <div className="kinto-create-carpark__carpark-location">
          <div className="kinto-create-carpark__carpark-location__header">
            <Icon name="location-dot" />
            <Heading level="2">{t('status_services_location')}</Heading>
          </div>

          <FormField
            name="address"
            autoComplete="off"
            component={GoogleAutocompleteField}
            placeholder={t('address_header')}
            required
            className="kinto-create-carpark__carpark-location__input"
            validate={validations.address}
          />
        </div>

        <div className="kinto-create-carpark__parking-spaces">
          <div className="kinto-create-carpark__parking-spaces__header">
            <Icon name="square-parking" />
            <Heading level="2">{t('rewards-parking_spaces')}</Heading>
          </div>

          <FormField
            required
            name="numberOfParkingSpaces"
            className="kinto-create-carpark__parking-spaces__input"
            inputMode="numeric"
            component={InputField}
            autoComplete="off"
            placeholder={t('parking_management-number_of_parking_spaces')}
            validate={validations.numberOfParkingSpaces}
          />

          <Checkbox
            name="points_enabled"
            checked={enableParkingSpaces}
            onChange={handleSwitch}
            label={t('parking_management-parking_space_names')}
            labelPosition="left"
            className="kinto-create-carpark__parking-spaces__checkbox"
            disabled={
              !debouncedValue ||
              debouncedValue > ALLOWED_PARKING_SPACES_PER_CARPARK
            }
          />
          {validParkingSpotNumber && (
            <div className="kinto-create-carpark__parking-spaces__names">
              {renderCarspaceNames}
            </div>
          )}
        </div>

        <FormActions className="form__actions">
          <FormButtons.Submit
            id="submit_personal_info"
            disabled={form?.disableSubmitButton}
          />
          <FormButtons.Cancel
            onClick={() => {
              navigate(`/community/${communityId}/admin/parking-management`);
            }}
          />
        </FormActions>
      </Form>
    </Styled.ParkingManagement>
  );
};

export default CreateCarpark;
