import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Button, Icon, useUtilities } from '@faxi/web-component-library';
import ReservationForm from '../ReservationForm';

import { ApiReservations } from 'models';
import { apiParkingManagement } from 'modules';

import * as Styled from './ReservationPreview.styles';

type ReservationPreviewProps = {
  reservation: ApiReservations;
  spotId: number;
  day: string;
  onClose: () => void;
  onEdit: () => void;
  onDeleteReservation?: () => void;
};

/**
 * Used to display information about an already created reservation.
   Additionally, this component allows editing the reservation by calling the ReservationForm component,
   which contains the logic for editing the reservation. 
 */
const ReservationPreview = (props: ReservationPreviewProps) => {
  const { reservation, spotId, day, onClose, onEdit, onDeleteReservation } =
    props;
  const { t } = useTranslation();

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();

  const [edit, setEdit] = useState(false);

  const handleReleaseSpace = useCallback(async () => {
    try {
      showOverlay('.kinto-car-park__carspace__create-reservation');

      const { data } = await apiParkingManagement.deleteReservation(
        reservation.id
      );
      if (data) {
        onDeleteReservation?.();
        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('global-toast_changes_saved'),
          variant: 'success',
        });
        onClose();
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.kinto-car-park__carspace__create-reservation');
    }
  }, [
    reservation.id,
    onDeleteReservation,
    showSnackBar,
    t,
    onClose,
    showOverlay,
    hideOverlay,
  ]);

  return (
    <Styled.CreateReservationDropdown>
      {edit ? (
        <ReservationForm
          edit
          spotId={spotId}
          day={day}
          onReserve={onEdit}
          initialData={{
            title: reservation.title,
            description: reservation.note,
            reservationId: reservation.id,
          }}
          onClose={onClose}
        />
      ) : (
        <>
          <div className="reservation-preview__header">
            {reservation.title && <span>{reservation.title}</span>}
            <div className="reservation-preview__header__actions">
              <Button
                variant="ghost"
                icon={<Icon name="pen" />}
                onClick={() => setEdit(true)}
              />
              <Button
                variant="ghost"
                icon={<Icon name="xmark" />}
                onClick={onClose}
              />
            </div>
          </div>
          <div className="reservation-preview__status">
            <Icon name="square-parking" />
            <div className="reservation-preview__status__label">
              {t('global-reserved')}
            </div>
          </div>
          <div className="reservation-preview__date">
            <span>{dayjs(reservation.start_date).format('DD MMM, YYYY')}</span>
            00:00 - 24:00
          </div>
          {reservation.note && (
            <div className="reservation-preview__note">
              <span className="reservation-preview__note__label">
                {t('global-note')}
              </span>
              <span className="reservation-preview__note__text">
                {reservation.note}
              </span>
            </div>
          )}
          <Button variant="outline" onClick={handleReleaseSpace}>
            {t('parking-button_release_space')}
          </Button>
        </>
      )}
    </Styled.CreateReservationDropdown>
  );
};

export default ReservationPreview;
