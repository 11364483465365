import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@faxi/web-component-library';
import classNames from 'classnames';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { weekDays } from '../../constants';
import CarparkOccupancy from '../CarparkOccupancy';

import * as Styled from './CarparkCalendar.styles';
import { UserContext } from '../../../../store';

dayjs.extend(isoWeek);

type CarparkCalendarProps = {
  selectedWeek: number;
  onChangeWeek: Dispatch<SetStateAction<number>>;
  capacity: number;
  occupancyPerDay?: { date: string; occupancy: number }[];
};

const CarparkCalendar = (props: CarparkCalendarProps) => {
  const { selectedWeek, onChangeWeek, capacity, occupancyPerDay } = props;

  const {
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const { t } = useTranslation();

  const formatedWeek = useMemo(
    () =>
      `${dayjs()
        .add(selectedWeek, 'week')
        .startOf('isoWeek')
        .format('DD')} - ${dayjs()
        .add(selectedWeek, 'week')
        .endOf('isoWeek')
        .format('DD MMM YYYY')}`,
    [selectedWeek]
  );

  const getCurrentWeekDays = useMemo(() => {
    const startOfWeek = dayjs().add(selectedWeek, 'week').startOf('isoWeek');
    return Array.from({ length: 7 }, (_, i) =>
      startOfWeek.add(i, 'day').format(dateFormat)
    );
  }, [selectedWeek, dateFormat]);

  const today = useMemo(() => dayjs().format(dateFormat), [dateFormat]);

  return (
    <Styled.CarparkCalendar>
      <div className="kinto-carpark-calendar__header">
        <div className="kinto-carpark-calendar__dates">
          <div>{formatedWeek}</div>
          <Button
            variant="ghost"
            icon={<Icon name="chevron-left-solid" />}
            onClick={() => {
              onChangeWeek((old) => old - 1);
            }}
          />
          <Button
            variant="ghost"
            icon={<Icon name="chevron-right-solid" />}
            onClick={() => {
              onChangeWeek((old) => old + 1);
            }}
          />
        </div>

        <Button
          icon={<Icon name="regular-calendar-day" />}
          variant="ghost"
          onClick={() => onChangeWeek(0)}
        >
          {t('Today')}
        </Button>
      </div>

      <div className="kinto-carpark-calendar__days">
        {getCurrentWeekDays.map((day, index) => (
          <div
            key={`${day}-${index}`}
            className={classNames([
              'kinto-carpark-calendar__day',
              {
                'kinto-carpark-calendar__day--today': day === `${today}`,
              },
              {
                'kinto-carpark-calendar__day--past-day':
                  dayjs(day) < dayjs(today),
              },
            ])}
          >
            <span>
              {day === `${today}`
                ? t('Today').toUpperCase()
                : t(`${weekDays[index]}`).toUpperCase()}
            </span>

            <span className="kinto-carpark-calendar__day__index">
              {day.substring(0, 2)}
            </span>
          </div>
        ))}
      </div>
      <CarparkOccupancy
        selectedWeek={selectedWeek}
        capacity={capacity}
        occupancyPerDay={occupancyPerDay || []}
      />
    </Styled.CarparkCalendar>
  );
};

export default CarparkCalendar;
