import { config, ProgressBar } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useMemo } from 'react';
import * as Styled from './CarparkOccupancy.styles';

dayjs.extend(isoWeek);

type CarparkOccupancyProps = {
  selectedWeek: number;
  capacity: number;
  occupancyPerDay: { date: string; occupancy: number }[];
};

const CarparkOccupancy = (props: CarparkOccupancyProps) => {
  const { selectedWeek, capacity, occupancyPerDay } = props;

  const getCurrentWeekDays = useMemo(() => {
    const startOfWeek = dayjs().add(selectedWeek, 'week').startOf('isoWeek');
    return Array.from({ length: 7 }, (_, i) =>
      startOfWeek.add(i, 'day').format(config.apiDateFormat)
    );
  }, [selectedWeek]);

  return (
    <Styled.CarparkOccupancy>
      {getCurrentWeekDays.map((day, index) => (
        <div className="kinto-carpark-occupancy__day" key={index}>
          <div className="kinto-carpark-occupancy__day__label">{`${
            occupancyPerDay.find((occ) => occ.date === day)?.occupancy || 0
          } of ${capacity}`}</div>

          <ProgressBar
            currentStep={
              occupancyPerDay.find((occ) => occ.date === day)?.occupancy || 0
            }
            numberOfSteps={capacity}
          />
        </div>
      ))}
    </Styled.CarparkOccupancy>
  );
};

export default CarparkOccupancy;
