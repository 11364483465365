import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
  FC,
} from 'react';
import {
  Button,
  GoogleMapsContext,
  theme,
  useEffectSkipFirst,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { MapDataContext } from 'pages/Map/providers/MapData';

import UserPin from '../UserPin';
import { MarkerClickHandler } from '../UserPin/UserPin.component';
import updateMarkerIcon from './utils/updateMarkerIcon';
import { MessagesProvider, UserContext } from 'store';
import { MarkerClusterContext } from 'pages/Map/providers/MarkerCluster';
import { Home } from 'models';
import { Icon, SendMessageModal } from 'components';

const MapUserPins: FC = () => {
  const { t } = useTranslation();

  const { map } = useContext(GoogleMapsContext);

  const { communityId, user } = useContext(UserContext);
  const { locations } = useContext(MapDataContext);
  const { activePin, setActivePin } = useContext(MarkerClusterContext);

  const sendMessageBtnRef = useRef<HTMLButtonElement>(null);

  const [activeMarker, setActiveMarker] = useState<google.maps.Marker | null>(
    null
  );
  const [selectedUser, setSelectedUser] = useState<Home | null>(null);

  const [mapPlaceClicked, setMapPlaceClicked] = useState(false);

  const infoWindow = useRef<google.maps.InfoWindow>();
  const closeInfoBtnRef = useRef<HTMLButtonElement>(null);

  // MAP ID TO MARKER
  const markers = useRef<Map<number, google.maps.Marker>>(new Map());

  const activePinInfoWindowRef = useRef<HTMLDivElement>(null);

  const onMarkerClick: MarkerClickHandler = useCallback(
    (pin, marker) => {
      setMapPlaceClicked(false);

      setActivePin((old) => {
        if (old?.id === pin.id) {
          return null;
        }
        return pin;
      });

      setActiveMarker((old) => {
        if (old && old.getTitle() === marker.getTitle()) {
          updateMarkerIcon(old, false);

          return null;
        }

        if (old) {
          updateMarkerIcon(old, false);
        }

        updateMarkerIcon(marker, true);

        return marker;
      });
    },
    [setActivePin]
  );

  const onInitSendMessage = useCallback(() => {
    setSelectedUser(activePin);
  }, [activePin]);

  const onInfoWindowClose = useCallback(() => {
    infoWindow.current?.close();

    setMapPlaceClicked(false);

    setActiveMarker((old) => {
      if (old) {
        updateMarkerIcon(old, false);
      }
      return null;
    });
  }, []);

  const onMarkerAdd = useCallback((id: number, marker: google.maps.Marker) => {
    if (!markers.current.has(id)) {
      markers.current.set(id, marker);
    }
  }, []);

  // OPEN INFO WINDOW FOR ACTIVE USER MARKER
  useEffect(() => {
    if (!(activeMarker && map)) {
      return;
    }

    if (!infoWindow.current) {
      infoWindow.current = new window.google.maps.InfoWindow();
    }

    if (activePinInfoWindowRef.current) {
      infoWindow.current.setContent(activePinInfoWindowRef.current);
      infoWindow.current.open(map, activeMarker);
      setTimeout(() => closeInfoBtnRef.current?.focus(), 0);
    }
  }, [activeMarker, map]);

  useEffect(() => {
    if (!activePin) {
      infoWindow.current?.close();
    }
  }, [activePin]);

  useEffect(() => {
    const markersRef = markers.current;

    markersRef.forEach((m) => m.setMap(null));
    markersRef.clear();

    return () => {
      markersRef.forEach((m) => m.setMap(null));
      markersRef.clear();
    };
  }, [locations, communityId]);

  // clean active pin when there is no active marker
  useEffectSkipFirst(() => {
    if (!activeMarker) {
      setActivePin(null);
    }
  }, [activeMarker]);

  useEffect(() => {
    if (!map) return;

    const onMapClicked = (
      e: google.maps.MapMouseEvent | google.maps.IconMouseEvent
    ) => {
      if (Object.hasOwn(e, 'placeId')) {
        setMapPlaceClicked(true);

        setActiveMarker((old) => {
          if (old) {
            updateMarkerIcon(old, false);
          }
          return null;
        });
      }
    };

    const listener = map.addListener('click', onMapClicked);

    return () => listener.remove();
  }, [map]);

  return (
    <>
      {locations.peopleHomes?.map((pHome) => (
        <UserPin
          key={pHome.id}
          {...pHome}
          onClick={onMarkerClick}
          onMarkerAdd={onMarkerAdd}
        />
      ))}

      {`${activePin?.id}` !== user?.id && (
        <div className="info-window">
          <div className="info-window__container" ref={activePinInfoWindowRef}>
            <Button
              ref={closeInfoBtnRef}
              aria-label={t('accessibility-close_user_pin_info')}
              className="info-window__container__close"
              id="abort_map_send_user_message"
              onClick={onInfoWindowClose}
              icon={<Icon name="xmark" />}
              variant="ghost"
            />
            <div className="info-window__container__name">{`${
              [activePin?.first_name, activePin?.last_name].join(' ').trim() ||
              activePin?.email
            }`}</div>
            <Button
              ref={sendMessageBtnRef}
              id="map_send_user_message"
              onClick={onInitSendMessage}
              className="info-window__container__send-button"
            >
              {t('send_message')}
            </Button>
          </div>
        </div>
      )}

      {!!activePin && (
        <style>
          {`
          .map .gm-style-iw-t > div > button {
            display: none!important;
          }
        `}
        </style>
      )}

      {mapPlaceClicked && (
        <style>
          {`
          .map .gm-style-iw-t > div {
            top: ${theme.sizes.SIZE_40};
          }
        `}
        </style>
      )}

      {selectedUser && communityId && (
        <MessagesProvider>
          <SendMessageModal
            organisationId={communityId}
            receiver={selectedUser}
            triggerRef={sendMessageBtnRef.current as HTMLElement}
            textareaPlaceholder={t('start_typing_here')}
            title={t('messages-title_send_message', {
              name:
                [selectedUser.first_name, selectedUser.last_name]
                  .join(' ')
                  .trim() || selectedUser.email,
            })}
            onClose={() => {
              setSelectedUser(null);
            }}
          />
        </MessagesProvider>
      )}
    </>
  );
};

export default MapUserPins;
