import {
  useContext,
  useEffect,
  useCallback,
  useState,
  FC,
  useRef,
  useMemo,
} from 'react';
import {
  Button,
  Image,
  Breadcrumbs,
  useResize,
  Heading,
  useUtilities,
  getColor,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { responsiveSm } from '@faxi/web-css-utilities';
import classNames from 'classnames';

import { UserContext } from 'store';
import { Icon } from 'components';

import DataPoint from 'components/_molecules/DataPoint';
import { apiPeople, apiPredefinedShifts } from 'modules';
import { useTranslation } from 'react-i18next';

import SavedShiftPattern from 'pages/Settings/PredefinedShifts/components/SavedShiftPattern.component';
import { CustomFields, PeoplePageUser, Shift, StatisticType } from 'models';

import * as Styled from './Profile.styles';
import ShiftSettingsModal from 'components/_modals/ShiftSettings';

const Profile: FC = (): JSX.Element => {
  const {
    communityId,
    community,
    user,
    userReady,
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const { showOverlay, hideOverlay } = useUtilities();

  const { userId } = useParams() as { userId: string };

  const [userStatistic, setUserStatistic] = useState<StatisticType>();
  const [customFields, setCustomFields] = useState<CustomFields[]>();

  const [shiftSettingsModal, setShiftSettingsModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [profileUser, setProfileUser] = useState<PeoplePageUser>();

  const editShift = useRef<Shift | undefined>(undefined);

  const [shifts, setShifts] = useState<Shift[]>([]);

  const assignedUsersShifts = useMemo(
    () =>
      ({ [`${profileUser?.id}`]: profileUser?.shifts } as Record<
        string,
        Shift[]
      >),
    [profileUser]
  );

  const assignUserShifts = useCallback((shifts?: Shift[]) => {
    setProfileUser((old) => ({ ...old, shifts } as PeoplePageUser));
  }, []);

  const closeShiftModal = useCallback(() => {
    setShiftSettingsModal(false);
  }, []);

  const getExactUser = useCallback(async () => {
    if (!communityId || !userReady) {
      return;
    }

    try {
      showOverlay('.profile__secondary-data__permissions');

      showOverlay('.profile__user-data');

      showOverlay('.profile__secondary-data__details');

      showOverlay('.profile__secondary-data__shift-settings');

      const { users } = await apiPeople.getExactUser(communityId, userId);

      // const imageUrl = URL.createObjectURL(imageBlob);

      const exactUser = users?.[0];
      if (exactUser) {
        setProfileUser({
          ...exactUser,
          name: [exactUser.first_name, exactUser.last_name].join(' ').trim(),
          image_url: exactUser.image_url,
          lastactive_ts: exactUser.lastactive_ts
            ? dayjs(exactUser.lastactive_ts).format(dateFormat)
            : '-',
          joined_ts: dayjs(exactUser.joined_ts).format(dateFormat),
          messages_count: exactUser.messages_count,
          shifts: exactUser.shifts,
          status:
            exactUser.authorised === 'Y'
              ? t('approved')
              : exactUser.authorised === 'N'
              ? t('fc_pending')
              : exactUser.authorised === 'D'
              ? t('deactivated')
              : t('rejected'),
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.profile__secondary-data__permissions');
      hideOverlay('.profile__user-data');
      hideOverlay('.profile__secondary-data__details');
      hideOverlay('.profile__secondary-data__shift-settings');
    }
  }, [communityId, userReady, showOverlay, userId, dateFormat, t, hideOverlay]);

  const getUserStatistic = useCallback(async () => {
    if (!communityId || !user || !userReady) {
      return;
    }

    try {
      showOverlay('.profile__secondary-data__journey');
      const userStats = await apiPeople.getUserStatistic(
        user.id,
        communityId,
        userId
      );
      setUserStatistic(userStats.stats);
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.profile__secondary-data__journey');
    }
  }, [communityId, hideOverlay, showOverlay, user, userId, userReady]);

  const getCustomFields = useCallback(async () => {
    if (!communityId) {
      return;
    }

    try {
      showOverlay('.profile__secondary-data__fields');

      const fields = await apiPeople.getCustomFields(communityId, userId);
      setCustomFields(fields.custom_fields);
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.profile__secondary-data__fields');
    }
  }, [communityId, hideOverlay, showOverlay, userId]);

  const isBelowSize = useResize(responsiveSm);

  const getShifts = useCallback(async () => {
    if (!communityId) {
      return;
    }

    try {
      showOverlay('.profile__secondary-data__shift-settings');

      const { shifts } = await apiPredefinedShifts.getShifts(communityId);
      setShifts(shifts);
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.profile__secondary-data__shift-settings');
    }
  }, [communityId, hideOverlay, showOverlay]);

  const breadcrumbs = useMemo(
    () => [
      {
        text: community?.name as string,
        href: `/community/${communityId}/admin/dashboard`,
        id: 'people_bdc_back_to_community',
      },
      {
        text: t('mPeople'),
        href: `/community/${communityId}/admin/people`,
        id: 'people_bdc_back_to_people',
      },
      {
        id: 'user-profile',
        text: (profileUser?.name || profileUser?.email) as string,
        href: '',
      },
    ],
    [community?.name, communityId, profileUser?.email, profileUser?.name, t]
  );

  useEffect(() => {
    getShifts();
  }, [getShifts]);

  useEffect(() => {
    getExactUser();
  }, [getExactUser]);

  useEffect(() => {
    getUserStatistic();
  }, [getUserStatistic]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  return (
    <Styled.Container className="profile">
      {/* BREADCRUMBS */}
      <div className="profile__user-data">
        <Breadcrumbs className="profile__breadcrumbs" crumbs={breadcrumbs} />
        {/* MAIN DATA */}
        <div className="profile__main-data">
          <div className="profile__main-data__avatar-container">
            <Image
              src={profileUser?.image_url || ''}
              alt={t('user_profile_picture', { user: profileUser?.name })}
              className="profile-img profile__main-data__avatar-container__avatar"
              fallbackUrl="/assets/svg/user_circle_placeholder.svg"
            />
            <Heading
              level="1"
              color={getColor('--SHADE_1_2')}
              className="profile__main-data__avatar-container__name"
            >
              {profileUser?.name}
            </Heading>
          </div>
          <div className="profile__main-data__data-points-container">
            <DataPoint
              label={t('last_active')}
              value={profileUser?.lastactive_ts}
            />
            <DataPoint
              label={t('fc_already_member')}
              value={profileUser?.joined_ts}
            />
            <DataPoint
              label={t('messages_sent')}
              value={profileUser?.messages_count}
            />
          </div>
        </div>
      </div>
      <div className="profile__secondary-data">
        <div className="profile__secondary-data__details">
          <Heading
            level="2"
            className="profile__secondary-data__details__title"
          >
            {t('details')}
          </Heading>
          <div className="profile__secondary-data__data-points-container">
            <DataPoint
              label={t('register_fname_hint')}
              value={profileUser?.first_name}
              rowOrder={!isBelowSize}
            />
            <DataPoint
              label={t('register_lname_hint')}
              value={profileUser?.last_name}
              rowOrder={!isBelowSize}
            />
            <DataPoint
              label={t('dw_email_address')}
              value={profileUser?.email}
              rowOrder={!isBelowSize}
            />
          </div>
        </div>
        <div className="profile__secondary-data__permissions">
          <Heading
            level="2"
            className="profile__secondary-data__permissions__title"
          >
            {t('permissions')}
          </Heading>
          <div className="profile__secondary-data__data-points-container">
            <DataPoint
              label={t('ga_gd_status')}
              value={profileUser?.status}
              rowOrder={!isBelowSize}
            />
            <DataPoint
              label={t('header_role')}
              value={`${t('person')} ${
                profileUser?.is_admin === 'Y' ? `, ${t('admin')}` : ''
              }`}
              rowOrder={!isBelowSize}
            />
          </div>
          <Button
            id="edit_permissions"
            icon={<Icon name="chevron-right" />}
            variant="ghost"
            iconPosition="right"
            onClick={() => navigate(`permissions`)}
          >
            {t('edit_permissions')}
          </Button>
        </div>
      </div>
      <div className="profile__secondary-data">
        <div className="profile__secondary-data__fields">
          <Heading level="2" className="profile__secondary-data__fields__title">
            {t('custom_fields')}
          </Heading>
          <div className="profile__secondary-data__data-points-container">
            {customFields?.map((item, index) => (
              <DataPoint
                key={index}
                label={item.label}
                value={item.value || '-'}
                rowOrder={!isBelowSize}
              />
            ))}
            {!customFields?.length && (
              <div className="profile__secondary-data__data-points-container__empty">
                {t('custom_fields_placeholder')}
              </div>
            )}
          </div>
        </div>
        <div className="profile__secondary-data__journey">
          <Heading
            level="2"
            className="profile__secondary-data__journey__title"
          >
            {t('number_of_journeys')}
          </Heading>
          <div className="profile__secondary-data__journey__content">
            <div className="profile__secondary-data__journey__content__box">
              <div className="profile__secondary-data__journey__content__box__value">
                {userStatistic?.trips}
              </div>
              <div className="profile__secondary-data__journey__content__box__icon">
                <Icon name="route" color={getColor('--ACCENT_1_1')} />
              </div>
              <div className="profile__secondary-data__journey__content__box--label">{`${t(
                'total'
              )} ${t('mJourneys')}`}</div>
            </div>
            <div className="profile__secondary-data__journey__content__box">
              <div className="profile__secondary-data__journey__content__box__value">
                {userStatistic?.trips_carpool}
              </div>
              <div className="profile__secondary-data__journey__content__box__icon">
                <Icon name="car" color={getColor('--ACCENT_1_1')} />
              </div>
              <div className="profile__secondary-data__journey__content__box--label">{`${t(
                'Carpool'
              )} ${t('mJourneys')}`}</div>
            </div>
            <div className="profile__secondary-data__journey__content__box">
              <div className="profile__secondary-data__journey__content__box__value">
                {userStatistic?.trips_walk}
              </div>
              <div className="profile__secondary-data__journey__content__box__icon">
                <Icon name="person-walking" color={getColor('--ACCENT_1_1')} />
              </div>
              <div className="profile__secondary-data__journey__content__box--label">
                {t('cp_map_walking')}
              </div>
            </div>
            <div className="profile__secondary-data__journey__content__box">
              <div className="profile__secondary-data__journey__content__box__value">
                {userStatistic?.trips_cycle}
              </div>
              <div className="profile__secondary-data__journey__content__box__icon">
                <Icon name="person-biking" color={getColor('--ACCENT_1_1')} />
              </div>
              <div className="profile__secondary-data__journey__content__box--label">
                {t('cp_map_cycling')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {profileUser?.authorised !== 'R' && (
        <div className="profile__secondary-data">
          <div className="profile__secondary-data__shift-settings">
            <Heading
              level="2"
              className="profile__secondary-data__fields__title"
            >
              {t('shift_settings-button_shift_settings')}
            </Heading>
            <div>
              {(profileUser?.shifts as Shift[])?.length > 0 ? (
                profileUser?.shifts.map((shift: Shift, index: number) => (
                  <SavedShiftPattern
                    key={index}
                    shift={shift}
                    onEdit={() => {
                      editShift.current = {
                        ...shift,
                      };
                      setShiftSettingsModal(true);
                    }}
                  />
                ))
              ) : (
                <p className="profile__secondary-data__shift-settings__empty-state-text">
                  {t(
                    'shift_settings-no_available_shifts_start_adding_shifts_by_tapping_the_button_below'
                  )}
                </p>
              )}
              <Button
                variant="ghost"
                icon={<Icon name="plus" />}
                iconPosition="left"
                className={classNames({
                  'profile__secondary-data__shift-settings__add-shift-btn':
                    profileUser?.shifts,
                })}
                onClick={() => {
                  editShift.current = undefined;
                  setShiftSettingsModal(true);
                }}
              >
                {t('shift_settings-button_add_shift')}
              </Button>

              {shiftSettingsModal && (
                <ShiftSettingsModal
                  closeOnSave
                  closeOnDelete
                  shifts={shifts}
                  selectedShift={editShift.current}
                  assignedShifts={assignedUsersShifts}
                  onClose={closeShiftModal}
                  onChange={assignUserShifts}
                />
              )}
            </div>
          </div>
          <div className="profile__secondary-data__shift-settings__dummy"></div>
        </div>
      )}

      <Button
        id="return_to_people_page"
        icon={<Icon name="chevron-left" />}
        variant="ghost"
        onClick={() => navigate(-1)}
        iconPosition="left"
      >
        {t('back_to_people_list')}
      </Button>
    </Styled.Container>
  );
};

export default Profile;
