import { theme } from '@faxi/web-component-library';
import { size } from '@faxi/web-css-utilities';
import { styled } from 'styled-components';

export const CarparkOccupancyLegend = styled.div`
  display: flex;
  gap: ${theme.sizes.SIZE_20};
  margin-bottom: ${theme.sizes.SIZE_12};

  .kinto-carpark-occupancy-legend {
    display: flex;
    font-size: ${theme.fontSizes.FONT_12};
    color: var(--SHADE_1_1);
    gap: ${theme.sizes.SIZE_10};

    &__status {
      ${size(theme.sizes.SIZE_40, theme.sizes.SIZE_16)}
      border-radius: ${theme.sizes.SIZE_4};

      &--available {
        background-color: var(--ALERT_SUCCESS_1_7);
      }

      &--reserved-by-admin {
        background-color: var(--PRIMARY_1_1);
      }
      &--reserved-by-mobile {
        background-color: var(--SECONDARY_3_1);
      }
    }
  }
`;
