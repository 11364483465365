import httpClient from '../httpClient';

const getNetPromoterScore = () => httpClient.get(`platform-surveys/active`);

const addNetPromoterScoreAnswer = (surveyId: number, mark: number | null) => {
  const answer = {
    answers: {
      q1: mark,
    },
  };

  return httpClient.post(`platform-surveys/${surveyId}/answers`, answer);
};

export default {
  getNetPromoterScore,
  addNetPromoterScoreAnswer,
};
