import styled, { css } from 'styled-components';
import { Modal, ModalProps, theme } from '@faxi/web-component-library';
import { phablet, mobile, fontSize, pxToRem } from '@faxi/web-css-utilities';
import { flex } from '@faxi/web-css-utilities';

export const Container = styled.div`
  flex: 1 1 100%;
  &.parking-space-allocation {
    width: 100%;
    color: var(--SHADE_1_2);
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    table {
      width: 100%;
      border-spacing: 0;
      margin-bottom: ${theme.sizes.SIZE_64};
      tr:last-child {
        border-bottom: none;
      }
      th,
      td {
        border-collapse: collapse;
        padding-top: ${theme.sizes.SIZE_12};
        padding-bottom: ${theme.sizes.SIZE_12};
      }
      th {
        font-weight: 400;
        color: var(--SHADE_1_3);
      }
      td {
        padding-bottom: ${theme.sizes.SIZE_12};
        color: var(--SHADE_1_1);
        ${fontSize(theme.fontSizes.FONT_14)};
        font-weight: 400;
        border-top: 1px solid var(--SHADE_1_9);
      }
      ${mobile(css`
        td {
          border-top: none;
          padding: ${theme.sizes.SIZE_2} 0;
          span:first-of-type {
            color: var(--SHADE_1_2);
            font-weight: 400;
          }
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: ${pxToRem(theme.sizes.SIZE_120)};
          }
        }
        tr {
          border-bottom: 1px solid var(--SHADE_1_9);
          padding: 0px ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_10};
          :not(:first-of-type) {
            padding-top: ${theme.sizes.SIZE_32};
          }
        }
        tbody {
          margin-top: ${theme.sizes.SIZE_32};
        }
      `)}
    }
  }
  .parking-space-allocation {
    &__table {
      overflow: auto;
      max-height: ${pxToRem(theme.sizes.SIZE_288)};
      ${mobile(css`
        margin-top: ${theme.sizes.SIZE_32};
        max-height: ${pxToRem(theme.sizes.SIZE_248)};
        table {
          margin-bottom: 0;
        }
      `)}
    }
    &__footer {
      width: 100%;
      padding: ${theme.sizes.SIZE_8};
    }
    &__placeholder {
      ${flex('row', 'center', 'center')};
      font-weight: 500;
      color: var(--SHADE_1_2);
      margin: auto;
    }
  }
`;

export const ParkingSpotAllocationModal = styled((props: ModalProps) => (
  <Modal {...props} />
))`
  .modal {
    padding-bottom: ${theme.sizes.SIZE_64};
    background-color: var(--BACKGROUND_1_1);
    .parking-space-allocation-modal {
      &__search {
        margin-bottom: ${theme.sizes.SIZE_20};
        width: ${pxToRem(theme.sizes.SIZE_240)};
        ${mobile(css`
          width: 100%;
        `)}
      }
      th,
      td {
        border-collapse: collapse;
        padding-top: ${theme.sizes.SIZE_12};
        padding-bottom: ${theme.sizes.SIZE_12};
      }
      th {
        padding-right: ${theme.sizes.SIZE_72};
      }
      td {
        border-top: 1px solid var(--SHADE_1_9);
      }
      table {
        border-spacing: 0;
        text-align: left;
        overflow: auto;
        max-height: ${pxToRem('350px')};
        margin-bottom: ${theme.sizes.SIZE_48};
        tr:last-child {
          border-bottom: none;
        }
        &__row {
          th {
            color: var(--SHADE_1_3);
            ${fontSize(theme.fontSizes.FONT_14)};
            font-weight: 400;
          }
        }
        ${mobile(css`
          td {
            border-top: none;
            span:first-of-type {
              color: var(--SHADE_1_2);
              font-weight: 400;
            }
            span:last-child {
              margin-left: 0;
            }
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: ${pxToRem(theme.sizes.SIZE_120)};
            }
            padding: ${theme.sizes.SIZE_2} 0;
          }
          tr {
            border-bottom: 1px solid var(--SHADE_1_9);
            padding: 0px ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_10};
            :not(:first-of-type) {
              padding-top: ${theme.sizes.SIZE_32};
            }
          }
          tbody {
            margin-top: ${theme.sizes.SIZE_32};
          }
        `)}
      }
      .page-selector {
        margin-top: ${theme.sizes.SIZE_20};
      }
    }
    .parking-space-allocation {
      &__table {
        ${mobile(css`
          overflow: auto;
          max-height: ${pxToRem('350px')};
          margin-top: ${theme.sizes.SIZE_32};
          table {
            margin-bottom: 0;
          }
        `)}
      }
      &__divider-row {
        display: block;
        padding: ${theme.sizes.SIZE_16} 0;
        &__divider {
          position: absolute;
          left: 0;
          right: 0;
          background-color: var(--SHADE_1_5);
          height: ${pxToRem(theme.sizes.SIZE_1)};
          padding: 0;
          ${phablet(
            css`
              display: none;
            `
          )};
        }
      }
      &__placeholder {
        color: var(--SHADE_1_2);
        margin: auto;
      }
    }
  }
`;
