import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  GoogleMapsContext,
  useEffectSkipFirst,
  useUtilities,
} from '@faxi/web-component-library';

import { apiCommunity } from 'modules';
import { UserContext } from 'store';
import { Home, Place } from 'models';
import { useAbortController } from 'hooks';
import { isCancel } from 'axios';
import MapRoute from 'pages/Map/components/MapRoute';

export type Coordinates = { lat: number; lng: number };

export const MapDataContext = createContext<{
  locations: {
    peopleHomes?: (Home & { imageSrc?: string })[];
    places?: Place[];
  };
  routeStart: Coordinates | undefined;
  onMarkerAdd: (id: number, marker: google.maps.Marker) => void;
  clearMarkers: () => void;
  setRouteStart: Dispatch<SetStateAction<Coordinates | undefined>>;
}>({
  locations: {},
  routeStart: undefined,
  onMarkerAdd: () => {},
  clearMarkers: () => {},
  setRouteStart: () => {},
});

const MapDataProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const { showOverlay, hideOverlay } = useUtilities();

  const { map } = useContext(GoogleMapsContext);

  const { communityId, user, userReady } = useContext(UserContext);

  const [locations, setLocations] = useState<{
    peopleHomes?: (Home & { imageSrc?: string })[];
    places?: Place[];
  }>({});
  const [routeStart, setRouteStart] = useState<Coordinates>();

  // MAP ID TO MARKER
  const markers = useRef<Map<number, google.maps.Marker>>(new Map());

  const {
    abortSignal: abortCommunityPlacesSignal,
    cancelPreviousRequest: cancelCommunityPlacesRequest,
  } = useAbortController();

  const workMarker = useMemo(
    () =>
      locations.places?.find(
        (p) => +p.ido === communityId && p.type === 'Group'
      ),
    [communityId, locations]
  );

  const routePoints = useMemo<Coordinates[]>(() => {
    if (!routeStart || !workMarker) {
      return [];
    }
    return [routeStart, { lat: +workMarker.lat, lng: +workMarker.lng }];
  }, [routeStart, workMarker]);

  const fetchMarkersData = useCallback(async () => {
    // DO NOT ASK ME WHY
    // BASED ON OUR ARCHITECTURE, THIS HAD TO BE WRITTEN
    if (!communityId || !user || !userReady) {
      return;
    }

    try {
      cancelCommunityPlacesRequest();

      showOverlay('.main-template');

      const {
        data: { users: userHomes },
      } = await apiCommunity.getCommunityUsers(communityId, {
        signal: abortCommunityPlacesSignal(),
      });

      const { id, image_url, firstname, lastname, name, lat, lng, email } =
        user;

      const adminHome: Omit<Home, 'lat' | 'lng'> & {
        latitude: number;
        longitude: number;
        image_url?: string;
      } = {
        id: +id,
        image_url,
        first_name: firstname,
        last_name: lastname || name,
        latitude: +lat,
        longitude: +lng,
        email,
      };

      setLocations({
        peopleHomes: [adminHome, ...userHomes].map((home) => {
          const privacyOffset = (4 * Math.random() - 2) / 1e4;

          const { latitude, longitude, ...restHome } = home;

          return {
            ...restHome,
            lat: latitude + privacyOffset,
            lng: longitude + privacyOffset,
          };
        }),
        places: user.places.filter(
          (p) => +p.ido === communityId && p.type === 'Group'
        ),
      });
    } catch (e) {
      if (!isCancel(e)) {
        console.error(e);
      }
    } finally {
      hideOverlay('.main-template');
    }
  }, [
    abortCommunityPlacesSignal,
    cancelCommunityPlacesRequest,
    communityId,
    hideOverlay,
    showOverlay,
    user,
    userReady,
  ]);

  const onMarkerAdd = useCallback((id: number, marker: google.maps.Marker) => {
    if (!markers.current.has(id)) {
      markers.current.set(id, marker);
    }
  }, []);

  const clearMarkers = useCallback(() => {
    markers.current.forEach((m) => m.setMap(null));
    markers.current.clear();
  }, []);

  const moveToSelectedMarker = useCallback(() => {
    if (!map || !routeStart) {
      return;
    }

    map.panTo({
      lat: routeStart.lat,
      lng: routeStart.lng,
    });

    map.setZoom(20);
  }, [map, routeStart]);

  // FIT MAP TO MARKERS
  useEffect(() => {
    moveToSelectedMarker();
  }, [moveToSelectedMarker]);

  // FETCH MARKERS DATA
  useEffect(() => {
    setLocations({});
    fetchMarkersData();
  }, [fetchMarkersData]);

  // CLEAR AND SET FETCHING FLAG
  useEffectSkipFirst(() => {
    clearMarkers();
  }, [communityId]);

  // GENERATE PLACES MARKERS (COMMUNITY ICON)
  useEffect(() => {
    if (!map) {
      return;
    }

    if (locations.places) {
      locations.places.forEach(({ lat, lng, ido }) => {
        const marker = new window.google.maps.Marker({
          position: { lat: +lat, lng: +lng },
          map,
          icon: '/assets/svg/office_pin.svg',
        });

        markers.current.set(+ido, marker);
      });
    }
  }, [locations, map]);

  useEffect(() => {
    if (!map || !(workMarker?.lat && workMarker?.lng)) {
      return;
    }

    map.panTo({ lat: +workMarker.lat, lng: +workMarker.lng });
    if (map.getZoom() !== 17) {
      map.setZoom(17);
    }
  }, [map, workMarker]);

  return (
    <MapDataContext.Provider
      value={{
        locations,
        routeStart,
        onMarkerAdd,
        clearMarkers,
        setRouteStart,
      }}
    >
      {children}

      {routePoints && <MapRoute points={routePoints} />}
    </MapDataContext.Provider>
  );
};

export default MapDataProvider;
