import styled, { css } from 'styled-components';
import {
  ListView,
  Modal,
  ModalProps,
  theme,
} from '@faxi/web-component-library';
import { flex, fontSize, phablet, pxToRem } from '@faxi/web-css-utilities';

export const ConsentsContainer = styled.div`
  ${flex('column', 'center', 'flex-start')};
  height: 100%;

  .data-consents {
    &__additional-info-btn {
      margin: 0 0 ${theme.sizes.SIZE_32};
    }

    &__header {
      margin-bottom: ${theme.sizes.SIZE_16};

      > a {
        color: var(--ACCENT_1_1);
      }
    }

    &__list {
      ${ListView(theme.sizes.SIZE_32, 'initial', 'stretch')};
    }

    &__footer {
      ${fontSize(theme.sizes.SIZE_12)};
      word-break: normal;
      overflow-wrap: anywhere;
      color: var(--SHADE_1_2);
      margin-top: ${theme.sizes.SIZE_24};
    }
  }
`;

export const ConsentModal = styled((props: ModalProps) => <Modal {...props} />)`
  padding-top: ${theme.sizes.SIZE_48};

  .modal[class] {
    max-width: ${pxToRem(theme.sizes.SIZE_744)};

    ${phablet(css`
      ${flex('column', 'flex-start', 'flex-start')};

      height: 100%;
      margin-top: ${theme.sizes.SIZE_48};
    `)}
  }

  .data-consents-modal {
    &__body-title {
      ${fontSize(theme.fontSizes.FONT_14)};
      padding-left: ${theme.sizes.SIZE_16};
      font-weight: 400;
      overflow: auto;

      > ol {
        padding: 0;
        margin: 0;
      }
    }
  }
`;
