import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import { styled } from 'styled-components';

export const CarparkOccupancy = styled.div`
  ${flex('row')}
  width: 100%;
  gap: ${theme.sizes.SIZE_10};
  margin-top: ${theme.sizes.SIZE_16};

  .kinto-carpark-occupancy {
    &__day {
      flex: 1;

      &__label {
        font-size: ${theme.fontSizes.FONT_14};
        color: var(--ALERT_SUCCESS_DARK_1_1);
        line-height: ${theme.sizes.SIZE_20};
        margin-bottom: ${theme.sizes.SIZE_4};
      }
    }
  }

  .kinto-progress-bar__container__bar {
    height: ${theme.sizes.SIZE_8};
    border-radius: ${theme.sizes.SIZE_8};

    &::after {
      border-radius: ${theme.sizes.SIZE_8};
    }
  }
`;
