import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  Button,
  Icon,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { FormField, FormRef, validators } from '@faxi/web-form';
import { InputField, TextareaField } from 'components';

import { apiParkingManagement } from 'modules';
import { UserContext } from 'store';

import * as Styled from './ReservationForm.styles';

type ReservationFormProps = {
  spotId: number;
  day: string;
  edit?: boolean;
  capacity?: number;
  selectedCount?: number;
  initialData?: { title?: string; description?: string; reservationId: number };
  onReserve?: () => void;
  onClose: () => void;
};

/**
 * Serves as a form for creating and editing reservations.
 * It opens in a popup when clicking on a field in the parking table of parking spots.
 */
const ReservationForm = (props: ReservationFormProps) => {
  const {
    spotId,
    day,
    edit = false,
    capacity,
    selectedCount,
    initialData,
    onReserve,
    onClose,
  } = props;

  const { t } = useTranslation();

  const { parkingId } = useParams() as { parkingId: string };
  const { user } = useContext(UserContext);

  const { showOverlay, showSnackBar, hideOverlay } = useUtilities();

  const [form, formRef] = useCallbackRef<FormRef>();

  const handleSubmit = useCallback(
    async (values: { title: string; description: string }) => {
      const { title, description } = values;

      const reservationParams = {
        title,
        parking_id: parkingId,
        spot_id: spotId,
        user_id: user?.id,
        status: 'active',
        start_date: dayjs(day).format('YYYY-MM-DD hh:mm'),
        end_date: dayjs(day).format('YYYY-MM-DD hh:mm'),
        note: description,
      };

      try {
        showOverlay('.kinto-car-park__carspace__create-reservation');

        let res;

        if (edit) {
          const { data } = await apiParkingManagement.editReservation(
            initialData?.reservationId!,
            title,
            description
          );
          res = data;
        } else {
          const { data } = await apiParkingManagement.createReservation(
            reservationParams
          );
          res = data;
        }

        if (res) {
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t('global-toast_changes_saved'),
            variant: 'success',
          });

          onReserve?.();
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.kinto-car-park__carspace__create-reservation');
      }
    },
    [
      day,
      edit,
      initialData?.reservationId,
      onReserve,
      parkingId,
      showOverlay,
      hideOverlay,
      showSnackBar,
      spotId,
      t,
      user?.id,
    ]
  );

  const validations = useMemo(
    () => ({
      title: [
        validators.general.maxLength(
          20,
          t('validation_max_length', {
            value: '20',
          })
        ),
      ],
      description: [
        validators.general.maxLength(
          300,
          t('validation_max_length', {
            value: '300',
          })
        ),
      ],
    }),
    [t]
  );

  return (
    <Styled.ReservationForm
      className="create-reservation-form"
      onSubmit={handleSubmit}
      initialData={initialData}
      ref={formRef}
    >
      <div className="create-reservation-form__title">
        <FormField
          name="title"
          className="create-reservation-form__title__input"
          component={InputField}
          autoComplete="off"
          placeholder={t('global-title')}
          validate={validations.title}
        />
        <Button
          className="create-reservation-form__close-btn"
          variant="ghost"
          icon={<Icon name="xmark" />}
          onClick={onClose}
        />
      </div>
      {!edit && (
        <div className="create-reservation-form__parking-spaces">
          <Icon name="square-parking" />
          <span className="create-reservation-form__parking-spaces__label">
            {t('parking_management-selected_spaces', {
              firstnumber: selectedCount,
              number: capacity,
            })}
          </span>
        </div>
      )}

      <div className="create-reservation-form__dates">
        <Icon name="calendar" />
        {day}
      </div>

      <FormField
        name="description"
        component={TextareaField}
        autoComplete="off"
        placeholder={t('global-note')}
        className="create-reservation-form__description"
        validate={validations.description}
      />

      {!edit ? (
        <Button className="create-reservation-form__submit-btn" type="submit">
          {t('global-reserve')}
        </Button>
      ) : (
        <div className="create-reservation-form__actions">
          <Button
            type="submit"
            disabled={!form?.isFormChanged(['reservationId'])}
          >
            {t('CommuteDetails_saving_message')}
          </Button>
          <Button variant="ghost" type="button" onClick={onClose}>
            {t('Cancel')}
          </Button>
        </div>
      )}
    </Styled.ReservationForm>
  );
};

export default ReservationForm;
