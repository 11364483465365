import { theme } from '@faxi/web-component-library';
import { fontSize, phablet } from '@faxi/web-css-utilities';
import { css, styled } from 'styled-components';
import { PageLayout } from '../../../components';

export const ParkingManagement = styled(PageLayout)`
  &.kinto-create-carpark {
    padding-right: 20%;

    ${phablet(css`
      padding-right: ${theme.sizes.SIZE_64};
    `)}
  }

  .kinto-create-carpark {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-top: ${theme.sizes.SIZE_16};
      margin-bottom: ${theme.sizes.SIZE_24};

      h1 {
        color: var(--PRIMARY_1_1);
      }
    }

    &__carpark-location {
      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_1_1);
      padding: ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_24};

      h2 {
        font-size: ${theme.fontSizes.FONT_18};
        line-height: 1;
        color: var(--SECONDARY_1_1);
      }

      &__header {
        display: flex;
        gap: ${theme.sizes.SIZE_8};
        margin-bottom: ${theme.sizes.SIZE_16};
      }

      &__input {
        max-width: ${theme.sizes.SIZE_288};
      }

      svg {
        color: var(--PRIMARY_1_1);
      }
    }

    &__parking-spaces {
      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_1_1);
      padding: ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_24};
      h2 {
        font-size: ${theme.fontSizes.FONT_18};
        line-height: 1;
        color: var(--SECONDARY_1_1);
      }

      &__header {
        display: flex;
        gap: ${theme.sizes.SIZE_8};
        margin-bottom: ${theme.sizes.SIZE_16};

        svg {
          color: var(--PRIMARY_1_1);
        }
      }

      &__input {
        max-width: ${theme.sizes.SIZE_232};
      }

      &__switch {
        flex-direction: row;
        margin-top: ${theme.sizes.SIZE_32};
        gap: ${theme.sizes.SIZE_16};

        .wcl-switch__label {
          font-size: ${theme.fontSizes.FONT_16};
          color: var(--SHADE_1_2);
        }
      }
      &__checkbox {
        gap: ${theme.sizes.SIZE_8};
        margin-top: ${theme.sizes.SIZE_16};

        .wcl-checkbox__label {
          ${fontSize(theme.fontSizes.FONT_12, theme.sizes.SIZE_20)};

          font-weight: 400;
          color: var(--SHADE_1_1);
        }
      }

      &__names {
        max-width: ${theme.sizes.SIZE_374};
        max-height: ${theme.sizes.SIZE_304};
        overflow: auto;
        padding-right: ${theme.sizes.SIZE_32};
        margin-top: ${theme.sizes.SIZE_40};
      }

      &__space {
        margin-bottom: ${theme.sizes.SIZE_16};
      }
    }

    &__carpark-details {
      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_1_1);
      padding: ${theme.sizes.SIZE_16};

      .textarea__container {
        max-width: ${theme.sizes.SIZE_632};
        textarea {
          max-height: ${theme.sizes.SIZE_288};
          min-width: ${theme.sizes.SIZE_288};
        }
      }

      .input {
        margin-bottom: ${theme.sizes.SIZE_16};
        max-width: ${theme.sizes.SIZE_288};
      }
    }
  }
`;
