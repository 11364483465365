import { elevate_xxl, theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import { styled } from 'styled-components';

export const NetPromoterScorePopup = styled.div`
  background-color: var(--BACKGROUND_1_1);
  position: absolute;
  right: ${theme.sizes.SIZE_32};
  bottom: ${theme.sizes.SIZE_16};
  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  ${elevate_xxl};

  .kinto-net-promoter-score-popup {
    &__close-btn,
    &__action-btn {
      margin-left: auto;
    }

    &__question {
      font-size: ${theme.fontSizes.FONT_20};
      font-weight: 600;
      margin-bottom: ${theme.sizes.SIZE_20};
      max-width: ${theme.sizes.SIZE_560};
      color: var(--SHADE_1_1);
    }

    &__mark-lbl-container {
      ${flex('row', 'space-between')};
      font-size: ${theme.sizes.SIZE_12};
      padding-right: ${theme.sizes.SIZE_96};
      margin-top: ${theme.sizes.SIZE_12};

      > div {
        width: ${theme.sizes.SIZE_12};
      }
    }

    &__action-btn {
      margin-top: ${theme.sizes.SIZE_20};
    }

    &__completed {
      &__icon {
        margin-bottom: ${theme.sizes.SIZE_32};
        color: var(--PRIMARY_1_1);

        svg {
          ${size('50px')};
        }
      }
      &__title {
        font-size: ${theme.fontSizes.FONT_24};
        font-weight: 600;
        color: var(--SHADE_1_1);
        text-align: center;
        margin-bottom: ${theme.sizes.SIZE_16};
      }
      &__sub-title {
        font-size: ${theme.fontSizes.FONT_16};
        color: var(--SHADE_1_2);
        text-align: center;
        margin-bottom: ${theme.sizes.SIZE_20};
        max-width: ${theme.sizes.SIZE_320};
      }
      &__action-btn {
        width: 100%;
      }
    }
  }

  .wcl-radio-button--chip .wcl-radio-button__label {
    ${size(theme.sizes.SIZE_48)};
    padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_12};
    text-align: center;
  }
`;
