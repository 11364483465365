import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  config,
  Heading,
  Icon,
  useEventListener,
  useUtilities,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { ApiReservations, Parking } from 'models';
import { apiParkingManagement } from 'modules';
import { UserContext } from 'store';
import CarparkCalendar from '../components/CarparkCalendar';
import CarparkOccupancyLegend from '../components/CarparkOccupancyLegend';
import ReservationPreview from '../components/ReservationPreview';
import ReservationForm from '../components/ReservationForm';
import { calendarDateFormat } from '../constants';

import * as Styled from './CarParkPage.styles';

const CarParkPage = () => {
  const { communityId, user } = useContext(UserContext);

  const { parkingId } = useParams() as { parkingId: string };

  const { t } = useTranslation();
  const { showOverlay, hideOverlay } = useUtilities();

  const [carPark, setCarPark] = useState<Parking>();
  const [reservations, setReservations] = useState<ApiReservations[]>();
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [selectedParking, setSelectedParking] = useState<string>();

  const [selectedRange, setSelectedRange] = useState<number[]>([]);
  const [startIndex, setStartIndex] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const carparkContainerRef = useRef(null);

  const getCurrentWeekDays = useMemo(() => {
    const startOfWeek = dayjs().add(selectedWeek, 'week').startOf('isoWeek');
    return Array.from({ length: 7 }, (_, i) =>
      startOfWeek.add(i, 'day').format(calendarDateFormat)
    );
  }, [selectedWeek]);

  /*
    Fetch carpark and reservations
  **/
  const getCarParkInfo = useCallback(async () => {
    try {
      showOverlay('#main');

      const {
        data: { data },
      } = await apiParkingManagement.getCarParkById(parkingId);

      if (communityId && user) {
        const {
          data: {
            data: { reservations },
          },
        } = await apiParkingManagement.getReservations(communityId, parkingId);

        setReservations(reservations);
      }
      if (data) {
        setCarPark(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('#main');
    }
  }, [communityId, hideOverlay, parkingId, showOverlay, user]);

  useEffect(() => {
    getCarParkInfo();
  }, [getCarParkInfo]);

  // Handles when the user clicks to start selection
  const handleMouseDown = useCallback((day: string, index: number) => {
    setSelectedParking(day);
    setStartIndex(index);
    setSelectedRange([index]);
    setIsDragging(true);
  }, []);

  // Handles drag movement
  const handleMouseEnter = useCallback(
    (index: number) => {
      if (isDragging && startIndex !== null) {
        const min = Math.min(startIndex, index);
        const max = Math.max(startIndex, index);
        setSelectedRange(
          Array.from({ length: max - min + 1 }, (_, i) => i + min)
        );
      }
    },
    [isDragging, startIndex]
  );

  // Handles when the user releases the mouse
  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const occupancyPerDay = useMemo(
    () =>
      reservations?.reduce(
        (acc: { date: string; occupancy: number }[], reservation) => {
          const date = dayjs(reservation.start_date).format(
            config.apiDateFormat
          );
          const entry = acc.find((item) => item.date === date);

          if (entry) {
            entry.occupancy++;
          } else {
            acc.push({ date, occupancy: 1 });
          }

          return acc;
        },
        []
      ),
    [reservations]
  );

  const balanceForDay = useCallback(
    (day: string) => {
      if (!carPark?.capacity) return;

      const formatedDay = dayjs(day).format(config.apiDateFormat);

      const occupancyForDay =
        occupancyPerDay?.find((occ) => occ.date === formatedDay)?.occupancy ||
        0;
      return carPark?.capacity - occupancyForDay;
    },
    [carPark?.capacity, occupancyPerDay]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        id: 'people_bdc_back_to_community',
        text: t('global-parking_management'),
        href: `/community/${communityId}/admin/parking-management`,
      },
      {
        id: 'user-profile',
        text: carPark?.name || t('joinGroup_current_carpark'),
        href: '',
      },
    ],
    [carPark?.name, communityId, t]
  );

  /*
    Restart selected range,
    When close reservation dropdown˝
  **/
  useEventListener({
    listener: 'click',
    callback: (ev: Event) => {
      if (
        carparkContainerRef.current &&
        !(carparkContainerRef?.current as any)?.contains(ev.target)
      ) {
        setSelectedRange([]);
      }
    },
  });

  return (
    <Styled.CarParkPage>
      <Breadcrumbs crumbs={breadcrumbs} />

      {carPark && (
        <>
          <div className="kinto-car-park__heading">
            <Heading level="1">{carPark?.name}</Heading>
            <div className="kinto-car-park__heading__location">
              <Icon name="location-dot" />
              <span>{carPark?.address}</span>
            </div>
          </div>

          <CarparkCalendar
            capacity={carPark?.capacity}
            selectedWeek={selectedWeek}
            onChangeWeek={setSelectedWeek}
            occupancyPerDay={occupancyPerDay}
          />

          <CarparkOccupancyLegend />

          <div className="kinto-car-park__table">
            <div className="kinto-car-park__table__index-container">
              {carPark.spots.map((el, ind) => (
                <div className="kinto-car-park__table__index" key={el.id}>
                  {el.name || ++ind}
                </div>
              ))}
            </div>

            <div
              className="kinto-car-park__carspace-container"
              ref={carparkContainerRef}
            >
              {getCurrentWeekDays.map((day, index) => (
                <div
                  className="kinto-car-park__carspace"
                  key={index}
                  onMouseUp={() => handleMouseUp()}
                  tabIndex={0}
                >
                  {carPark.spots.map((el, ind) => {
                    const reservation = reservations?.find(
                      (reservation) =>
                        reservation.spot_id === el.id &&
                        dayjs(reservation.start_date).format(
                          calendarDateFormat
                        ) === day
                    );

                    return (
                      <div
                        tabIndex={0}
                        key={`${day}-${ind}`}
                        className={classNames([
                          'kinto-car-park__carspace__day',
                          {
                            'kinto-car-park__carspace__day--past': dayjs(
                              day
                            ).isBefore(dayjs(), 'day'),
                          },
                          {
                            'kinto-car-park__carspace__day--selected':
                              selectedParking === day &&
                              selectedRange.includes(ind) &&
                              !reservation,
                          },
                          {
                            'kinto-car-park__carspace__day--reserved-by-admin':
                              !!reservation,
                          },
                        ])}
                        onMouseDown={() =>
                          !dayjs(day).isBefore(dayjs(), 'day') &&
                          !(
                            selectedParking === day &&
                            selectedRange.includes(ind)
                          ) &&
                          handleMouseDown(day, ind)
                        }
                        onMouseEnter={() => handleMouseEnter(ind)}
                      >
                        {!!reservation && (
                          <div className="kinto-car-park__carspace__day__info">
                            {reservation.title && (
                              <span>{reservation.title}</span>
                            )}
                            <span className="kinto-car-park__carspace__day__info__time">
                              00:00 - 24:00
                            </span>
                          </div>
                        )}
                        {selectedParking === day &&
                          selectedRange[selectedRange.length - 1] === ind &&
                          !dayjs(day).isBefore(dayjs(), 'day') && (
                            <div
                              className={classNames([
                                'kinto-car-park__carspace__create-reservation',
                                {
                                  'kinto-car-park__carspace__create-reservation--left':
                                    getCurrentWeekDays.findIndex(
                                      (weekDay) => weekDay === day
                                    ) >
                                    Math.floor(getCurrentWeekDays.length / 2),
                                },
                                {
                                  'kinto-car-park__carspace__create-reservation--right':
                                    getCurrentWeekDays.findIndex(
                                      (weekDay) => weekDay === day
                                    ) <
                                    Math.ceil(getCurrentWeekDays.length / 2),
                                },
                              ])}
                              onClick={(ev) => {
                                ev.stopPropagation();
                              }}
                            >
                              {reservation ? (
                                <ReservationPreview
                                  reservation={reservation}
                                  onClose={() => setSelectedRange([])}
                                  onDeleteReservation={() => getCarParkInfo()}
                                  spotId={el.id}
                                  day={day}
                                  onEdit={() => {
                                    getCarParkInfo();
                                    setSelectedRange([]);
                                  }}
                                />
                              ) : (
                                <ReservationForm
                                  spotId={el.id}
                                  day={day}
                                  onReserve={() => {
                                    getCarParkInfo();
                                    setSelectedRange([]);
                                  }}
                                  capacity={balanceForDay(day)}
                                  selectedCount={selectedRange.length}
                                  onClose={() => setSelectedRange([])}
                                />
                              )}
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </Styled.CarParkPage>
  );
};

export default CarParkPage;
