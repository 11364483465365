import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  PageSelector,
  Table,
  useKeyboardListener,
  useUtilities,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { isCancel } from 'axios';

import { Icon, InputField } from 'components';
import { apiCommunity } from 'modules';
import { UserContext } from 'store';
import { useAbortController } from 'hooks';

import { ParkingSpotLegacy } from 'models';
import config from 'config';

import * as Styled from './ParkingSpaceAllocation.styles';

const NUMBER_OF_INIT_PARKING_SPOTS = 5;

const ParkingSpaceAllocation = (): JSX.Element => {
  const { isValidCommunity } = useContext(UserContext);
  const { t } = useTranslation();

  const { showOverlay, hideOverlay } = useUtilities();

  const { organisationId } = useParams<{ organisationId: string }>();

  const [carpark, setCarpark] = useState<
    (Partial<ParkingSpotLegacy> & { id: number })[]
  >([]);
  const [carparkFirstFive, setCarparkFirstFive] = useState<
    (Partial<ParkingSpotLegacy> & { id: number })[]
  >([]);

  const [showWholeListModal, setShowWholeListModal] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalParkingSpots, setTotalParkingSpots] = useState<number>(0);
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const {
    abortSignal: abortParkingSlotSignal,
    cancelPreviousRequest: cancleParkingSlotRequest,
  } = useAbortController();

  const totalPages = useMemo(
    () => Math.ceil(totalParkingSpots / NUMBER_OF_INIT_PARKING_SPOTS),
    [totalParkingSpots]
  );

  const translationKeys = useMemo(
    () =>
      ({
        name: t('parkNRide_username'),
        user_vrm: t('registration_no'),
        ocps_name: t('parking_space'),
        ocp_name: t('cpt_carpark'),
      } as Record<Partial<keyof (ParkingSpotLegacy & { id: number })>, string>),
    [t]
  );

  const getParkingSlot = useCallback(async () => {
    if (!isValidCommunity) return;
    try {
      cancleParkingSlotRequest();

      showOverlay('.parking-space-allocation-modal');
      const { rc, carpark, total } =
        await apiCommunity.getParkingSlotAllocation(
          +organisationId!,
          dayjs().format(config.apiDateFormat),
          NUMBER_OF_INIT_PARKING_SPOTS,
          (currentPage - 1) * NUMBER_OF_INIT_PARKING_SPOTS,
          debouncedSearch,
          { signal: abortParkingSlotSignal() }
        );

      if (rc === 'ok') {
        setCarpark(
          carpark.map(
            (
              {
                id_user,
                login,
                firstname,
                name,
                user_vrm,
                ocps_name,
                ocp_name,
              },
              index
            ) => ({
              id: index,
              name:
                firstname || name ? `${firstname} ${name}` : login || id_user,
              user_vrm,
              ocps_name,
              ocp_name,
            })
          )
        );

        if (carparkFirstFive.length === 0) {
          setCarparkFirstFive(
            carpark.map(
              (
                {
                  id_user,
                  login,
                  firstname,
                  name,
                  user_vrm,
                  ocps_name,
                  ocp_name,
                },
                index
              ) => ({
                id: index,
                name:
                  firstname || name ? `${firstname} ${name}` : login || id_user,
                user_vrm,
                ocps_name,
                ocp_name,
              })
            )
          );
        }

        setTotalParkingSpots(total);
      }
    } catch (e) {
      if (!isCancel(e)) {
        console.error(e);
      }
    } finally {
      hideOverlay('.parking-space-allocation-modal');
      hideOverlay('.parking-space-allocation');
    }
  }, [
    isValidCommunity,
    cancleParkingSlotRequest,
    showOverlay,
    organisationId,
    currentPage,
    debouncedSearch,
    abortParkingSlotSignal,
    carparkFirstFive.length,
    hideOverlay,
  ]);

  const onSearchChange = useRef(
    debounce((text: string) => {
      setDebouncedSearch(text);
      setCurrentPage(1);
    }, 300)
  );

  useKeyboardListener(
    [
      {
        keyCodes: ['Escape'],
        callbackFn: () => setShowWholeListModal(false),
      },
    ],
    window
  );

  useEffect(() => {
    onSearchChange.current(search);
  }, [onSearchChange, search]);

  useEffect(() => {
    getParkingSlot();
  }, [getParkingSlot]);

  useEffect(() => {
    if (!showWholeListModal) {
      setSearch('');
    }
  }, [showWholeListModal]);

  // Initial spinner because same api call is used eather for table and in modal table
  // this is used to avoid overlapping spinners
  useEffect(() => {
    showOverlay('.parking-space-allocation');

    return () => {
      hideOverlay('.parking-space-allocation');
    };
  }, [hideOverlay, showOverlay]);

  return (
    <Styled.Container
      className="parking-space-allocation"
      id="parking-space-allocation"
    >
      {carparkFirstFive?.length ? (
        <Table
          tableId="parking-space-first-five-table"
          className="parking-space-allocation__table"
          tableData={carparkFirstFive}
          translationKeys={translationKeys}
          disableSort={true}
          excludeColumns={['id']}
        />
      ) : (
        <div className="parking-space-allocation__placeholder">
          {t('parking_spot_allocation_placeholder')}
        </div>
      )}

      {showWholeListModal && (
        <Styled.ParkingSpotAllocationModal
          onClose={() => setShowWholeListModal(false)}
          title={t('parking_spot_allocation')}
        >
          <div className="parking-space-allocation-modal">
            <InputField
              className="parking-space-allocation-modal__search"
              value={search}
              id="car_park_search_input"
              onChange={setSearch}
              {...(search && {
                suffixIcon: (
                  <Button
                    variant="ghost"
                    aria-label={t('delete_input')}
                    icon={<Icon name="xmark" />}
                    onClick={() => setSearch('')}
                  />
                ),
              })}
              prefixIcon={<Icon name="magnifying-glass" />}
              placeholder={`${t('selgroup_search')}...`}
            />

            {carpark?.length ? (
              <>
                <Table
                  tableId="parking-space-table"
                  className="parking-space-allocation__table"
                  tableData={carpark}
                  translationKeys={translationKeys}
                  disableSort={true}
                  excludeColumns={['id']}
                />
                {totalParkingSpots > NUMBER_OF_INIT_PARKING_SPOTS && (
                  <PageSelector
                    selected={currentPage}
                    numberOfPages={totalPages}
                    onPageChange={setCurrentPage}
                    ariaLeftLabel={t('accessibility-button_previous_page')}
                    ariaRightLabel={t('accessibility-button_next_page')}
                  />
                )}
              </>
            ) : (
              <div className="parking-space-allocation__placeholder">
                {t('parking_spot_allocation_placeholder')}
              </div>
            )}
          </div>
        </Styled.ParkingSpotAllocationModal>
      )}

      <div className="parking-space-allocation__footer">
        <Button
          id="view_parking_slot_allocation"
          type="button"
          variant="ghost"
          onClick={() => {
            setShowWholeListModal(true);
          }}
          icon={<Icon name="chevron-right" />}
          iconPosition="right"
          className="parking-space-allocation__whole-list-button"
          disabled={carpark?.length < NUMBER_OF_INIT_PARKING_SPOTS}
        >
          {t('view_whole_list')}
        </Button>
      </div>
    </Styled.Container>
  );
};

export default ParkingSpaceAllocation;
