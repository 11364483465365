import styled, { css } from 'styled-components';

import {
  flex,
  fontSize,
  marginChildren,
  mobile,
  pxToRem,
} from '@faxi/web-css-utilities';
import { Modal, ModalProps, theme } from '@faxi/web-component-library';

export const ActionModal = styled((props: ModalProps) => <Modal {...props} />)`
  word-break: normal;
  overflow-wrap: anywhere;

  .action-modal {
    &__note {
      ${flex('row', 'flex-start', 'flex-start')};

      width: 100%;
      max-width: ${pxToRem('392px')};

      > span {
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)}

        font-weight: 400;
        color: var(--SHADE_2_1);
      }

      &__icon {
        margin: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_12} 0
          ${theme.sizes.SIZE_4};
      }
    }

    &__text {
      ${fontSize(theme.fontSizes.FONT_18)};

      display: flex;
      font-weight: 600;
      align-items: baseline;
      color: var(--SHADE_1_1);
      max-width: ${pxToRem(theme.sizes.SIZE_488)};

      &__icon {
        margin-right: ${theme.sizes.SIZE_10};
      }
    }

    &__actions {
      ${flex('row', 'flex-end', 'center')};
      margin-top: ${theme.sizes.SIZE_48};
      ${marginChildren(`0 ${theme.sizes.SIZE_72} 0 0`)};

      ${mobile(css`
        align-self: center;
      `)}
    }
  }
`;
