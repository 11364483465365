import { ExtendRouteObject } from 'router/routes';
import { appUri } from 'config';
import Pages from 'pages';
import CreateCarpark from 'pages/ParkingManagement/CreateCarpark';
import CarParkPage from 'pages/ParkingManagement/CarParkPage';

export default [
  {
    handle: 'parking-management',
    path: '',
    element: <Pages.ParkingManagement />,
  },
  {
    handle: 'create-parking-management',
    path: appUri.COMMUNITY_PARKING_CREATE,
    element: <CreateCarpark />,
  },
  {
    handle: 'car-park',
    path: appUri.COMMUNITY_PARKING_CAR_PARK,
    element: <CarParkPage />,
  },
] as ExtendRouteObject[];
