import { FC, useCallback } from 'react';
import { Input, InputProps } from '@faxi/web-component-library';
import { FieldProps, useFieldError } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';

const preventWheelScrolling = (event: React.WheelEvent<HTMLInputElement>) => {
  const target = event.target as HTMLElement;

  target.blur();
  event.stopPropagation();
};

type ValueOnChange = (event: string) => void;

export type InputFieldProps = FieldProps<string, ValueOnChange> &
  InputProps & {
    displayError?: boolean;
    convertFunction?: (value: string) => string;
    externalError?: boolean;
  };

const InputField: FC<InputFieldProps> = (
  props: InputFieldProps
): JSX.Element => {
  const {
    type,
    dirty,
    error,
    touched,
    required,
    displayError = true,
    // case when InputField is outside of FormField
    externalError = false,
    onChange,
    convertFunction,
    ...rest
  } = props;

  const { t } = useTranslation();
  const showError = useFieldError(props);

  const handleOnChange = useCallback(
    (value: string) => {
      const convertedValue = convertFunction ? convertFunction(value) : value;
      onChange?.(convertedValue);
    },
    [convertFunction, onChange]
  );

  return (
    <Input
      {...(displayError && {
        error: showError || externalError,
        errorText: String(error),
      })}
      type={type}
      onChange={handleOnChange}
      aria-invalid={showError}
      required={required}
      {...(required && {
        requiredLabel: t('global-input_field_required_label'),
      })}
      {...(type === 'number' && {
        onWheel: preventWheelScrolling,
      })}
      {...rest}
    />
  );
};

export default InputField;
