import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import * as Styled from './CarparkOccupancyLegend.styles';

const legendStates = [
  { status: 'available', translationKey: 'Available' },
  { status: 'reserved-by-admin', translationKey: 'Reserved (by admin)' },
  { status: 'reserved-by-mobile', translationKey: 'Reserved (in mobile app)' },
];

const CarparkOccupancyLegend = () => {
  const { t } = useTranslation();

  return (
    <Styled.CarparkOccupancyLegend>
      {legendStates.map((state, ind) => {
        return (
          <div className="kinto-carpark-occupancy-legend" key={ind}>
            <div
              className={classNames([
                'kinto-carpark-occupancy-legend__status',
                `kinto-carpark-occupancy-legend__status--${state.status}`,
              ])}
            />
            <div>{t(state.translationKey)}</div>
          </div>
        );
      })}
    </Styled.CarparkOccupancyLegend>
  );
};

export default CarparkOccupancyLegend;
