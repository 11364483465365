import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import { Form } from '@faxi/web-form';
import { styled } from 'styled-components';

export const ReservationForm = styled(Form)`
  .create-reservation-form {
    padding: ${theme.sizes.SIZE_16};
    min-width: ${theme.sizes.SIZE_504};

    &__title {
      ${flex('row', 'space-between', 'flex-start')}
      gap: ${theme.sizes.SIZE_16};

      &__input {
        flex: 1;
      }
    }

    &__close-btn {
      margin-left: auto;
    }

    &__parking-spaces {
      ${flex('row', undefined, 'center')};
      margin-top: ${theme.sizes.SIZE_32};
      gap: ${theme.sizes.SIZE_12};

      &__input {
        max-width: ${theme.sizes.SIZE_48};
      }

      &__label {
        font-weight: 600;
        color: var(--SHADE_1_1);
      }
    }

    &__dates {
      ${flex('row', undefined, 'center')};
      margin-top: ${theme.sizes.SIZE_32};
      gap: ${theme.sizes.SIZE_12};
      font-weight: 600;
      color: var(--SHADE_1_1);
    }

    &__description {
      margin-top: ${theme.sizes.SIZE_32};
      margin-right: 28px;

      .textarea__container {
        max-width: ${theme.sizes.SIZE_440};
        width: 100%;
        textarea {
          max-height: ${theme.sizes.SIZE_200};
          min-width: ${theme.sizes.SIZE_408};
        }
      }
    }

    &__submit-btn {
      margin-top: ${theme.sizes.SIZE_32};
    }

    &__actions {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_32};
      margin-top: ${theme.sizes.SIZE_32};
    }
  }

  .wcl-dropdown__trigger--open {
    border: ${theme.sizes.SIZE_4} solid var(--PRIMARY_2_1);
  }

  svg {
    color: var(--PRIMARY_1_1);
    ${size(theme.sizes.SIZE_16)};
  }
`;
