import { FC, memo, useCallback, useMemo, useRef } from 'react';
import {
  ModalProps,
  ModalRef,
  useCallbackRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';
import InputField from 'components/_molecules/InputField';

import * as Styled from './EditLinkModal.styles';

export type EditLinkModalProps = {
  code: string;
  onSave?: (value: string) => void;
} & ModalProps;

const EditLinkModal: FC<EditLinkModalProps> = (props) => {
  const { code, onSave, ...rest } = props;

  const { t } = useTranslation();

  const initialValues = useMemo(() => ({ fastCode: code }), [code]);

  const [form, formRef] = useCallbackRef<FormRef>();

  const modalRef = useRef<ModalRef>(null);

  const [FormButtons] = useFormButtons({
    submitLabel: t('CommuteDetails_saving_message'),
    cancelLabel: t('cancel'),
  });

  const validations = useMemo(
    () => ({
      fastCode: [
        validators.general.required(
          t('validation-field_is_required', { fieldname: t('global-link') })
        ),
        validators.general.regex(
          /^([a-zA-Z0-9-_.]+)$/,
          t('validation-field_valid_link')
        ),
        validators.general.maxLength(
          30,
          t('validation-field_validation_max_length', {
            fieldname: t('name').toLowerCase(),
            number: '30',
          })
        ),
      ],
    }),
    [t]
  );

  const handleOnSubmit = useCallback(
    async (values: { fastCode: string }) => {
      const { fastCode } = values;

      onSave?.(fastCode);

      modalRef.current?.close();
    },
    [onSave]
  );

  const modalForm = useCallback(
    ({ children }: any) => (
      <Form
        ref={formRef}
        id="edit-link-modal-form"
        initialData={initialValues}
        onSubmit={handleOnSubmit}
        className="edit-link-modal"
        children={children}
      />
    ),
    [formRef, handleOnSubmit, initialValues]
  );

  return (
    <Styled.EditLinkModalContainer
      ref={modalRef}
      childrenWrapper={modalForm}
      renderAsPortal
      titleId="edit-invite-link-title"
      title={t('invite-people_title_edit_link')}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t('invite-people_title_edit_link'),
      })}
      footer={
        <div className="edit-link-modal__actions">
          <FormButtons.Submit disabled={form?.disableSubmitButton} />
          <FormButtons.Cancel onClick={() => modalRef.current?.close()} />
        </div>
      }
      {...rest}
    >
      <fieldset>
        <legend data-hidden hidden>
          {t('invite-people_title_edit_link')}
        </legend>
        <FormField
          id="community_invitation_edit_input"
          name="fastCode"
          type="text"
          autoComplete="off"
          component={InputField}
          validate={validations.fastCode}
          placeholder={t('global-link')}
        />
      </fieldset>
    </Styled.EditLinkModalContainer>
  );
};

export default memo(EditLinkModal);
