import { Spinner, getColor } from '@faxi/web-component-library';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon';

import * as Styled from './NetworkErrorOverlay.styles';

export type NetworkErrorOverlayType = {
  phase: '1' | '2' | '3';
};

const NetworkErrorOverlay = (props: NetworkErrorOverlayType): JSX.Element => {
  const { phase } = props;

  const { t } = useTranslation();

  const topMessage = useMemo(() => {
    switch (phase) {
      case '1':
        return <span>{t('check_internet_connection_try_again')}</span>;
      case '2':
        return (
          <Fragment>
            <div>{t('attempting_reconnect')}</div>
            <div className="network-error__top-message__phase-2">
              <div className="network-error__top-message__phase-2__text">
                {t('reconnecting')}
              </div>
              <Spinner size={18} color={getColor('--PRIMARY_1_1')} />
            </div>
          </Fragment>
        );
      case '3':
        return <span>{t('internet_connection_restored')}</span>;
    }
  }, [phase, t]);

  return (
    <Styled.Container $phase={phase} className="network-error">
      <div className="network-error__top-message">{topMessage}</div>
      {phase === '1' && (
        <div className="network-error__main">
          <Icon className="network-error__main__icon" name="wifi-slash" />
          <div className="network-error__main__text">
            {t('no_internet_connection')}
          </div>
        </div>
      )}
    </Styled.Container>
  );
};

export default NetworkErrorOverlay;
