import { createContext, Dispatch, SetStateAction } from 'react';
import { NetPromoterScoreAPIResponse } from '../../models';

interface NetPromoterScoreContext {
  netPromoterScore: NetPromoterScoreAPIResponse | null;
  setNetPromoterScore: Dispatch<
    SetStateAction<NetPromoterScoreAPIResponse | null>
  >;
  showNetPromoterScorePopup: boolean;
  discardNetPromoterScore: boolean;
  setDiscardNetPromoterScore: Dispatch<SetStateAction<boolean>>;
  getNetPromoterScore: () => void;
}

export const netPromoterScoreDefaultValue: NetPromoterScoreContext = {
  netPromoterScore: null,
  setNetPromoterScore: () => {},
  showNetPromoterScorePopup: false,
  discardNetPromoterScore: false,
  setDiscardNetPromoterScore: () => {},
  getNetPromoterScore: () => {},
};

const NetPromoterScoreContext = createContext<NetPromoterScoreContext>(
  netPromoterScoreDefaultValue
);

export default NetPromoterScoreContext;
