import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  Button,
  getColor,
  Heading,
  Image,
  Modal,
  useUtilities,
} from '@faxi/web-component-library';
import { UserContext } from 'store';
import { apiParkingManagement } from 'modules';
import { appUri } from 'config';
import { EmptyTab, Icon } from 'components';
import SpinnerWithLabel from './components/SpinnerWithLabel';
import CarParkElement from './components/CarParkElement';
import { Parking } from 'models';

import * as Styled from './ParkingManagement.styles';

const ParkingManagement = () => {
  const { communityId, isValidCommunity } = useContext(UserContext);
  const navigate = useNavigate();

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const [carParks, setCarParks] = useState<Parking[]>();
  const [carparkModal, setCarparkModal] = useState<Parking>();
  const [raisedBarrier, setRaisedBarrier] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const getCarParks = useCallback(async () => {
    try {
      if (!isValidCommunity || !communityId) return;

      showOverlay('.kinto-page');

      const {
        data: { data },
      } = await apiParkingManagement.getAllCarParks(communityId);

      if (data) {
        setCarParks((data as any).parkings);
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.kinto-page');
      setLoading(true);
    }
  }, [communityId, hideOverlay, isValidCommunity, showOverlay]);

  const onRaiseBarrier = useCallback(async () => {
    try {
      setRaisedBarrier(false);

      showOverlay(
        '.wcl-modal__main',
        undefined,
        <SpinnerWithLabel
          onFinish={() => {
            hideOverlay('.wcl-modal__main');
            setRaisedBarrier(true);
          }}
        />
      );

      const { data } = await apiParkingManagement.openCarParkBarrier();

      if (data.errc === 7003) {
        setCarparkModal(undefined);
        setRaisedBarrier(false);

        showSnackBar({
          variant: 'error',
          text: 'SMS message not sent, please try again later',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [hideOverlay, showOverlay, showSnackBar]);

  useEffect(() => {
    getCarParks();
  }, [getCarParks, hideOverlay]);

  return (
    <Styled.ParkingManagement className={classNames('kinto-page')}>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('global-parking_management')}
      </Heading>
      {loading &&
        (!carParks || carParks?.length === 0 ? (
          <EmptyTab
            icon="square-parking"
            title={t('parking_management-empty_state_title')}
            description={t('parking_management-empty_state_subtitle')}
            className="kinto-parking-management__empty-tab"
            actionTitle="Create carpark"
            onAction={() => navigate(appUri.COMMUNITY_PARKING_CREATE)}
          />
        ) : (
          <div>
            <Button
              className="kinto-parking-management__create-carpark-btn"
              icon={<Icon name="plus" />}
              iconPosition="left"
              variant="outline"
              onClick={() => {
                navigate(appUri.COMMUNITY_PARKING_CREATE);
              }}
            >
              {t('parking_management-create_carpark')}
            </Button>

            <div className="kinto-parking-management__carparks">
              {carParks.map((carpark) => (
                <CarParkElement
                  key={carpark.id}
                  carpark={carpark}
                  onRaiseBarrier={(ev) => {
                    ev.stopPropagation();
                    setCarparkModal(carpark);
                  }}
                  onClick={() => {
                    navigate(
                      `/community/${communityId}/admin/parking-management/${carpark.id}`
                    );
                  }}
                />
              ))}
            </div>
          </div>
        ))}

      {carparkModal && (
        <Modal
          onClose={() => {
            setCarparkModal(undefined);
            setRaisedBarrier(false);
            hideOverlay('.wcl-modal__main');
          }}
        >
          <Image
            alt="Closed carpark barrier"
            className={classNames('barrier-image', {
              'barrier-image--animate': raisedBarrier,
            })}
            src={'/assets/images/close-carpark-barrier.webp'}
          />
          <Image
            alt="Closed carpark barrier"
            src={'/assets/images/close-carpark-ramp.webp'}
          />
          <h1>
            {t('parking-title_carpark_barrier', { carpark: carparkModal.name })}
          </h1>
          {raisedBarrier ? (
            <div>
              <div className="kinto-parking-management__open-barrier-label">
                <Icon
                  name="check-circle-solid"
                  color={getColor('--ALERT_SUCCESS_1_1')}
                  className="resend-email__check-icon"
                />
                <div>{t('parking-state_barrier_opened')}</div>
              </div>
              <div className="kinto-parking-management__try-again">
                <div>{t('parking-state_button_barrier_not_raised')}</div>
                <Button onClick={onRaiseBarrier} variant="outline">
                  {t('global-try_again')}
                </Button>
              </div>
            </div>
          ) : (
            <Button onClick={onRaiseBarrier}>
              {t('parking-button_raise_a_barrier')}
            </Button>
          )}
        </Modal>
      )}
    </Styled.ParkingManagement>
  );
};

export default ParkingManagement;
