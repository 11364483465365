import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  Button,
  config,
  DatePicker,
  getColor,
  Heading,
  Image,
  Modal,
  ModalRef,
  PAGE_LIMIT,
  SelectOption,
  SortOption,
  SORT_OPTIONS,
  StatusElement,
  StatusElementStatus,
  Table,
  TableRef,
  useCallbackRef,
  useQueryParams,
  Tooltip,
} from '@faxi/web-component-library';
import { CommunityElement } from 'components';
import { useColumnSettings, useGenerateXLSX, useTablePagination } from 'hooks';
import { Booking, BookingAttendee, BookingStatus } from 'models';
import { UserContext } from 'store';
import dayjs from 'dayjs';
import { dateString } from 'utils';
import { apiBooking, settingsTranslations } from 'modules';
import { reportsConfig } from 'config/reportsConfig';

import * as Styled from './Bookings.styles';

const INITIAL_BOOKING_SORT = {
  sortBy: 'start_date',
  sortDirection: SORT_OPTIONS.ASC,
} as SortOption<Booking>;

const getBookingStatusElementStatus = (
  status: BookingStatus
): StatusElementStatus =>
  status === 'finished'
    ? 'approved'
    : status === 'upcoming'
    ? 'pending'
    : status === 'canceled'
    ? 'canceled'
    : 'active';

const getBookingStatusElementTranslation = (status: BookingStatus): string =>
  status === 'finished'
    ? 'journeys-history_status_finished'
    : status === 'upcoming'
    ? 'global-upcoming'
    : status === 'canceled'
    ? 'journeys-history_status_aborted'
    : 'global-status_active';

const composeAttendeeName = (
  first_name?: string,
  last_name?: string,
  screen_name?: string
) => (!first_name && !last_name ? screen_name : `${first_name} ${last_name}`);

const Bookings = () => {
  const {
    communityId,
    userPreferences: { dateFormat },
    userReady,
  } = useContext(UserContext);

  const { t } = useTranslation();

  const [participantsModal, setParticipantsModal] = useState<
    { booking_id: number; attendees: BookingAttendee[] } | undefined
  >(undefined);

  const participantsModalRef = useRef<ModalRef>(null);
  const [usersTable, usersTableRef] = useCallbackRef<TableRef>();

  const { params } = useQueryParams<{
    page: string;
    count: string;
  }>();

  const [dateRange, setDateRange] = useState({
    from: dayjs(),
    to: dayjs().add(29, 'day'),
  });

  const translationKeys = useMemo(
    () =>
      ({
        id: t(settingsTranslations.id),
        created_at: t('groupreg_js_created'),
        creator: t('global-creator'),
        participants: t('global-participants'),
        start_destination: t('global-start_destination'),
        end_destination: t('global-end_destination'),
        start_date: t('campaign-start_date'),
        start_time: t('predefined_shifts-start_time'),
        end_time: t('predefined_shifts-end_time'),
        co2_estimation: t('global-estimated_savings'),
        journey_id: t('global-journey_id'),
        status: t('ga_gd_status'),
      } as Record<Partial<keyof any>, string>),
    [t]
  );

  const {
    columnSettingsOpen,
    columnBtnRef,
    columnTranslations,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  const composeCreator = useCallback(
    (attendee?: BookingAttendee) => {
      if (!attendee) {
        return '-';
      } else {
        const {
          data: { first_name, last_name, screen_name, image },
        } = attendee;
        return (
          <div
            className={classNames(
              'table-data-with-image',
              'table-data-with-image--booking-page'
            )}
          >
            <Image
              className="profile-img"
              src={image || '/assets/svg/user_circle_placeholder.svg'}
              alt={t('user_profile_picture', {
                user: first_name,
              })}
            />
            <div className="table-data-with-image--booking-page__name">
              {composeAttendeeName(first_name, last_name, screen_name)}
            </div>
          </div>
        );
      }
    },
    [t]
  );

  const composeParticipants = useCallback(
    (
      attendees: BookingAttendee[],
      index: number,
      creator_id: string,
      booking_id: number
    ) => {
      const participants = attendees?.filter(
        (attendee) => attendee.user_id !== creator_id
      );

      return participants?.length === 0 ? (
        '-'
      ) : (
        <div
          className={classNames('table-data-with-several-images', {
            'table-data-with-several-images--grey': index % 2 !== 0,
          })}
          onClick={() => {
            // reorder array to set driver as first element of attendees, in modal driver must be first in list
            const indexOfDriver = attendees.findIndex(
              (attendee) => attendee.type === 'driver'
            );
            const [element] = attendees.splice(indexOfDriver, 1);
            attendees.unshift(element);

            setParticipantsModal({ attendees, booking_id });
          }}
        >
          {participants?.map((attendee) => (
            <Image
              className="profile-img"
              src={
                attendee.data.image || '/assets/svg/user_circle_placeholder.svg'
              }
              alt={t('user_profile_picture', {
                user: attendee.data.first_name,
              })}
              key={attendee.id}
            />
          ))}
        </div>
      );
    },
    [t]
  );

  const {
    data,
    count,
    totalPages,
    activeColumnSort,
    totalCount,
    currentPage,
    loading,
    setCount,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<Booking, 'appointments'>({
    itemsKey: 'appointments',
    deps: [communityId, params, dateRange],
    resetDeps: [communityId, dateRange],
    initialSortBy: INITIAL_BOOKING_SORT.sortBy,
    initialSortDirection: INITIAL_BOOKING_SORT.sortDirection,
    condition: userReady && !!Object.values(params).length,
    mappingFunction: async (tableData: Booking[]) =>
      tableData?.map(
        (
          {
            id,
            created_at,
            data,
            start_date,
            start_time,
            end_time,
            co2_estimation,
            status,
            attendees,
            creator_id,
          },
          index
        ) => ({
          id,
          created_at: dayjs(created_at).format(dateFormat),
          creator: composeCreator(
            attendees?.find((attendee) => attendee.user_id === creator_id)
          ),
          participants: composeParticipants(attendees!, index, creator_id!, id),
          start_destination: data?.start_address || '-',
          end_destination: data?.end_address || '-',
          start_date: start_date ? dayjs(start_date).format(dateFormat) : '-',
          start_time: start_time
            ? dateString({ time: start_time, format: 'HH:mm' })
            : '-',
          end_time: end_time
            ? dateString({ time: end_time, format: 'HH:mm' })
            : '-',
          co2_estimation: co2_estimation || 0,
          journey_id: data?.journey_id || '-',
          status: (
            <StatusElement
              status={getBookingStatusElementStatus(status as BookingStatus)}
            >
              {t(getBookingStatusElementTranslation(status as BookingStatus))}
            </StatusElement>
          ),
        })
      ),
    apiRequest: (count, offset, search, sort_by, sort_direction) =>
      apiBooking.getBookings({
        oid: communityId!,
        type: 'journey-appointment',
        count,
        offset,
        sort_by,
        sort_direction,
        ...(dateRange && {
          from: dateRange.from.format(config.apiDateFormat),
          to: dateRange.to.format(config.apiDateFormat),
        }),
      }),
  });

  const handleOnColumnSortClicked = useCallback(
    (sort: SortOption<Booking>) => {
      const { sortBy, sortDirection } = sort;
      setActiveColumnSort(sortBy, sortDirection);
    },
    [setActiveColumnSort]
  );

  // shown columns (selected from column settings)
  const selectedColumns = useMemo(
    () =>
      usersTable?.headerData.reduce((acc: string[], { selected, id }) => {
        if (selected) {
          acc.push(id);
        }
        return acc;
      }, []),
    [usersTable?.headerData]
  );

  const disableExclExport = useMemo(
    () => dateRange.to.diff(dateRange.from, 'days', true) > 90,
    [dateRange.from, dateRange.to]
  );

  const getBookings = useCallback(async () => {
    const array: any[][] = [];

    const { appointments } = await apiBooking.getBookings({
      oid: communityId!,
      type: 'journey-appointment',
      sort_by: activeColumnSort.sortBy as keyof Booking,
      sort_direction: activeColumnSort.sortDirection,
      from: dateRange?.from.format(config.apiDateFormat),
      to: dateRange?.to.format(config.apiDateFormat),
    });

    array.push(selectedColumns.map((el) => translationKeys?.[el]));

    (appointments as Booking[]).forEach(
      ({
        id,
        created_at,
        attendees,
        data,
        start_date,
        start_time,
        end_time,
        co2_estimation,
        creator_id,
        status,
      }) => {
        // finding creator from attendees
        const creator = attendees?.find(
          (attendee) => attendee.user_id === creator_id
        );

        const participants = attendees
          ?.filter((attendee) => attendee.user_id !== creator_id)
          .map(
            (participant) =>
              `${participant.data.first_name} ${participant.data.last_name}`
          );

        const mapData: Record<string, string | number | undefined> = {
          id,
          created_at: dayjs(created_at).format(dateFormat),
          creator: composeAttendeeName(
            creator?.data.first_name,
            creator?.data.last_name,
            creator?.data.screen_name
          ),
          participants: participants?.join(', ') || '-',
          start_destination: data?.start_address || '-',
          end_destination: data?.end_address || '-',
          start_date: start_date || '-',
          start_time: start_time
            ? dateString({ time: start_time, format: 'HH:mm' })
            : '-',
          end_time: end_time
            ? dateString({ time: end_time, format: 'HH:mm' })
            : '-',
          co2_estimation: co2_estimation || 0,
          journey_id: data?.journey_id || '-',
          status: status as string,
        };

        // use value from mapData only if column is shown
        const filteredObjValues = selectedColumns.map((el) => mapData[el]);

        array.push(filteredObjValues);
      }
    );

    return array;
  }, [
    activeColumnSort.sortBy,
    activeColumnSort.sortDirection,
    communityId,
    dateFormat,
    dateRange?.from,
    dateRange?.to,
    selectedColumns,
    translationKeys,
  ]);

  const columnWidths = useMemo(() => {
    const wCols: Record<string, number> = {
      id: 10,
      created_at: 15,
      creator: 25,
      participants: 40,
      start_destination: 35,
      end_destination: 35,
      start_date: 15,
      start_time: 10,
      end_time: 10,
      co2_estimation: 10,
      journey_id: 10,
      status: 10,
    };

    const wscols = selectedColumns?.map((column) => ({
      wch: wCols[column],
    }));

    return wscols;
  }, [selectedColumns]);

  const generateXLSX = useGenerateXLSX(
    t('mBookings'),
    () => getBookings(),
    columnWidths
  );

  return (
    <Styled.BookingPage className={classNames('kinto-page', 'bookings-page')}>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('mBookings')}
      </Heading>

      <Styled.HeaderBar>
        <CommunityElement />

        <div className="bookings-page__header-actions">
          <DatePicker
            value={dateRange}
            endingRangeDate={dayjs().add(2, 'year')}
            startingRangeDate={reportsConfig.range_start}
            dateFormat={dateFormat}
            openPosition="bottom-left"
            className="bookings-page__header-actions__date-picker"
            formButtonsLabels={{
              submitLabel: t('apply'),
              cancelLabel: t('cancel'),
            }}
            initialRadioDateRange={'30days'}
            rangeButtonsInFuture
            buttonsLabels={{
              customDate: t('custom_date'),
              last30Days: t('range_filter-next_days', { number: 30 }),
              last7Days: t('range_filter-next_days', { number: 7 }),
              last3Days: t('range_filter-next_days', { number: 3 }),
            }}
            onChange={setDateRange}
          />

          {data.length > 0 && (
            <div className="bookings-page__header-actions__table">
              <Tooltip
                content={t(
                  'bookings_tooltip_export_button_disabled_range'
                ).replace(/\\n/g, '<br />')}
                placement="top"
                disabled={!disableExclExport}
              >
                <div>
                  <Button
                    onClick={generateXLSX}
                    disabled={data.length === 0 || disableExclExport}
                    variant="outline"
                  >
                    {`${t('export_to')} XLSX`}
                  </Button>
                </div>
              </Tooltip>
              <ColumnSettingsButton>
                {t('global-column_settings')}
              </ColumnSettingsButton>
            </div>
          )}
        </div>
      </Styled.HeaderBar>

      {data.length === 0 && !loading ? (
        <div className="bookings-page__empty-state">
          <Image alt="" src="/assets/svg/no_bookings_illustration_image.svg" />
          <h3 className="bookings-page__empty-state__title">
            {t('global-no_bookings')}
          </h3>
          <div className="bookings-page__empty-state__subtitle">
            {t('bookings-subtitle_no_bookings')}
          </div>
        </div>
      ) : (
        <Table<Booking>
          ref={usersTableRef}
          cacheColumns
          tableId="bookings-table"
          tableData={data}
          translationKeys={translationKeys}
          loadingData={loading}
          excludeSortColumns={
            [
              'participants',
              'created_at',
              'journey_id',
              'start_destination',
              'end_destination',
              'start_time',
              'end_time',
              'creator',
              'status',
              'co2_estimation',
            ] as (keyof Booking)[]
          }
          columnSettingsOpen={columnSettingsOpen}
          columnsModalLabels={columnTranslations}
          columnsBtnElement={columnBtnRef?.current!}
          sortIconAriaLabel={(property, orientation) =>
            t(
              orientation === 'desc'
                ? 'accessibility-button_sort_ascending'
                : 'accessibility-button_sort_descending',
              { property: property.toLowerCase() }
            )
          }
          perPageLabel={t('per_page')}
          perPagePlaceholder={t('per_page')}
          initialSort={
            (activeColumnSort.sortBy
              ? activeColumnSort
              : INITIAL_BOOKING_SORT) as SortOption<Booking>
          }
          paginationData={{
            limit: count,
            totalPages,
            totalCount,
            currentPage,
          }}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          goToPageInputProps={{ placeholder: t('global-go_to_page') }}
          onColumnSortClicked={handleOnColumnSortClicked}
          onLimitChange={(data: SelectOption) => {
            setCount(+data.value as keyof typeof PAGE_LIMIT);
          }}
          onColumnsModalClose={closeColumnSettings}
        />
      )}

      {participantsModal && (
        <Modal
          ref={participantsModalRef}
          onClose={() => setParticipantsModal(undefined)}
          title={`${t('global_booking')} (${participantsModal?.booking_id})`}
          footer={
            <Button onClick={() => participantsModalRef.current?.close()}>
              {t('ok')}
            </Button>
          }
        >
          <Table<{
            id: number;
            participant: JSX.Element | string;
            mode: 'passenger' | 'driver';
          }>
            tableId="participants-table"
            tableData={participantsModal?.attendees?.map(
              (participant: BookingAttendee) => ({
                id: +participant.user_id,
                participant: composeCreator(participant),
                mode: participant.type,
              })
            )}
            excludeColumns={['id']}
            translationKeys={{
              id: 'id',
              participant: t('global-participants'),
              mode: t('mode_of_transport'),
            }}
            excludeSortColumns={['participant', 'mode']}
          />
        </Modal>
      )}
    </Styled.BookingPage>
  );
};

export default Bookings;
