import { PublicClientApplication } from '@azure/msal-browser';
import { createContext } from 'react';
import { getMsalConfig } from 'config/microsoftAuthConfig';

export const initialMsalInstance = new PublicClientApplication(getMsalConfig());

// Context to provide MSAL instance and update function
const MsalContext = createContext({
  msalInstance: initialMsalInstance,
  updateMsalInstance: async (
    clientId: string,
    tenantId: string,
    callback?: (msalInstance: any) => void
  ) => {},
});

export default MsalContext;
