import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { useFieldError, FieldProps, validationRegexes } from '@faxi/web-form';
import {
  Input,
  InputProps,
  PasswordRules,
  concatStrings,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon, { INameExtended } from '../../Icon';
import uniqid from 'uniqid';

import * as Styled from './PasswordField.styles';
import regex from 'validation/regex';

type ValueOnChange = (event: ChangeEvent<HTMLInputElement>) => void;

export type PasswordFieldProps = FieldProps<string, ValueOnChange> & {
  error?: string;
  className?: string;
  icon?: INameExtended;
  hasRules?: boolean;
} & InputProps;

const PasswordField: FC<PasswordFieldProps> = (
  props: PasswordFieldProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    error,
    label,
    dirty,
    touched,
    className,
    icon,
    id,
    hasRules,
    ...rest
  } = props;

  const { t } = useTranslation();

  const [inputType, setInputType] = useState('password');

  const [capsLockActive, setCapsLockActive] = useState(false);

  const showError = useFieldError(props);

  const actualId = useMemo(() => id || uniqid(), [id]);

  const capsLockChangedOnce = useRef(false);

  return (
    <Styled.Container className={classNames('kinto-password-field', className)}>
      <Input
        id={actualId}
        label={label}
        type={inputType}
        aria-describedby={concatStrings([
          hasRules && 'password_rules',
          capsLockChangedOnce.current &&
            capsLockActive &&
            `${rest.name}_capslock`,
        ])}
        aria-invalid={showError}
        onKeyUp={(e) => {
          if (
            e.key === 'CapsLock' ||
            (!e.getModifierState('CapsLock') && capsLockActive)
          ) {
            capsLockChangedOnce.current = true;
            setCapsLockActive(false);
          }
        }}
        onKeyDown={(e) => {
          if (
            e.key === 'CapsLock' ||
            (e.getModifierState('CapsLock') && !capsLockActive)
          ) {
            setCapsLockActive(true);
          }
        }}
        errorText={String(error)}
        error={showError}
        suffixIcon={
          <Icon
            className="kinto-password-field__eye"
            name={inputType === 'password' ? 'eye-slash' : 'eye'}
            onClick={() => {
              setInputType(inputType === 'password' ? 'text' : 'password');
            }}
          />
        }
        {...rest}
      />

      {capsLockChangedOnce.current && capsLockActive && (
        <div
          aria-live="assertive"
          id={`${rest.name}_capslock`}
          className="kinto-password-field__caps-lock-active"
        >
          {t('global-validation_caps_lock_is_on')}
        </div>
      )}

      {hasRules && (
        <PasswordRules
          translationFn={t}
          validationRegexes={{
            ...validationRegexes,
            passwordSpecialCharacter: regex.passwordSpecialCharacter,
          }}
          password={`${rest.value}`}
        />
      )}
    </Styled.Container>
  );
};

export default PasswordField;
