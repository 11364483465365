import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Heading,
  useCallbackRef,
  useQueryParams,
  useUtilities,
} from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  validationRegexes,
  validators,
} from '@faxi/web-form';
import Styled from 'components/_layouts/Containers';
import { loginRequest } from 'config/microsoftAuthConfig';
import { apiAuth } from 'modules';
import { AppContext, MsalWrapperContext } from 'store';
import { Icon, InputField } from 'components';

const LoginSSOForm = () => {
  const [form, formRef] = useCallbackRef<FormRef>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { showSnackBar } = useUtilities();

  const { updateMsalInstance } = useContext(MsalWrapperContext);
  const { platform } = useContext(AppContext);

  const {
    params: { signup },
  } = useQueryParams<{
    signup: string;
  }>();

  const handleLoginUser = useCallback(
    async (values: any) => {
      try {
        const {
          data: {
            data: { data },
          },
        } = await apiAuth.ssoLogin(values.email);

        if (data) {
          if (signup) {
            localStorage.setItem('signup', 'true');
            localStorage.setItem('platformId', `${platform?.id}`);
          }

          localStorage.setItem('sso_tenant_id', data.tenant_id);
          localStorage.setItem('sso_client_id', data.client_id);

          updateMsalInstance(data.client_id, data.tenant_id, (inst) => {
            inst
              .loginRedirect({
                ...loginRequest,
                loginHint: values.email,
                redirectUri: `${window.location.origin}/login-redirection`,
              })
              .catch((error: any) => console.error(error));
          });
        }
      } catch (e) {
        showSnackBar({
          variant: 'error',
          text: t('login_sso-subtitle_unable_to_log_in'),
        });
      }
    },
    [platform?.id, showSnackBar, signup, t, updateMsalInstance]
  );

  const validations = useMemo(
    () => ({
      email: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_email_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.workEmail,
          t('validation-field_valid_email', {
            fieldname: t('register_email_hint').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          80,
          t('validation-field_validation_max_length', {
            fieldname: t('register_email_hint').toLowerCase(),
            number: 80,
          })
        ),
      ],
    }),
    [t]
  );

  return (
    <Styled.AuthGrid className="login-sso-form">
      <Form className="form" onSubmit={handleLoginUser} ref={formRef}>
        <div className="form__fields">
          <Button
            variant="ghost"
            iconPosition="left"
            icon={<Icon name="arrow-left" />}
            onClick={() => {
              navigate(-1);
            }}
            className="form__fields__back-btn"
          >
            {t('Back')}
          </Button>

          <Heading level="1">
            {t(signup ? 'signup-sign_up_with_sso' : 'login-log_in_with_sso')}
          </Heading>

          <FormField
            className="form__fields__field"
            name="email"
            prefixIcon={<Icon name="envelope" />}
            label={t('register_work_email')}
            component={InputField}
            placeholder={t('enter_email')}
            validate={validations.email}
            required
            autoComplete="off"
          />

          <div className="form__fields__commands">
            <div className="form__fields__commands--standard">
              <Button
                id="submit_login"
                type="submit"
                className="login-btn"
                disabled={!form?.syncFormValid}
              >
                {t(signup ? 'new_onboarding_sign_up' : 'login')}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Styled.AuthGrid>
  );
};

export default LoginSSOForm;
