import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import { styled } from 'styled-components';

export const CarparkCalendar = styled.div`
  margin-bottom: ${theme.sizes.SIZE_24};

  .kinto-carpark-calendar {
    &__header {
      display: flex;
      gap: ${theme.sizes.SIZE_20};
      margin: ${theme.sizes.SIZE_12} 0;
    }

    &__dates {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_8};

      color: var(--PRIMARY_1_1);
      font-weight: 600;
      font-size: ${theme.sizes.SIZE_16};
    }

    &__day {
      ${flex('column', undefined, 'center')};

      background-color: var(--SHADE_1_9);
      padding: ${theme.sizes.SIZE_8};
      padding-top: ${theme.sizes.SIZE_12};
      font-size: ${theme.fontSizes.FONT_20};
      line-height: ${theme.fontSizes.FONT_28};
      border-radius: ${theme.sizes.SIZE_8};
      color: var(--PRIMARY_1_1);
      flex: 1;

      &__index {
        font-size: ${theme.fontSizes.FONT_34};
        line-height: ${theme.fontSizes.FONT_44};
      }

      &--today {
        background-color: var(--SHADE_1_6);
        color: var(--SHADE_1_1);

        .kinto-carpark-calendar__day__index {
          font-weight: 600;
        }
      }
    }

    &__days {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_10};
    }
  }
`;
