import { elevate_xxl, theme } from '@faxi/web-component-library';
import { styled } from 'styled-components';
import { PageLayout } from 'components';
import { flex, pxToRem } from '@faxi/web-css-utilities';

export const CarParkPage = styled(PageLayout)`
  .kinto-car-park {
    &__heading {
      display: flex;
      margin-top: ${theme.sizes.SIZE_8};
      h1,
      svg {
        color: var(--PRIMARY_1_1);
      }
      &__location {
        display: flex;
        margin-left: ${theme.sizes.SIZE_24};
        gap: ${theme.sizes.SIZE_8};
        align-items: center;
        color: var(--SHADE_1_1);
        margin-top: ${theme.sizes.SIZE_8};
      }
    }

    &__table {
      display: flex;
      margin-left: -${theme.sizes.SIZE_64};
      margin-bottom: ${theme.sizes.SIZE_304};

      &__index-container {
        margin-right: ${theme.sizes.SIZE_16};
        width: ${theme.sizes.SIZE_48};
      }

      &__index {
        height: 50px;
        align-self: center;
        color: var(--SHADE_1_1);
        margin: 5px 0;
        text-align: end;
        padding-top: ${theme.sizes.SIZE_6};
        line-height: ${pxToRem('22px')};
      }
    }

    &__carspace-container {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: ${theme.sizes.SIZE_10};
      width: 100%;
    }

    &__carspace {
      width: 100%;

      &__day {
        background-color: var(--ALERT_SUCCESS_1_7);
        color: white;
        padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_12};
        height: 50px;
        border-radius: ${theme.sizes.SIZE_8};
        font-size: ${theme.fontSizes.FONT_18};
        margin: 5px 0;
        cursor: pointer;
        font-size: ${theme.fontSizes.FONT_12};

        position: relative;

        &--past {
          background-color: var(--SHADE_1_7);
          cursor: not-allowed;
        }

        &--selected {
          border: ${theme.sizes.SIZE_4} solid var(--PRIMARY_2_1);
        }

        &--reserved-by-admin {
          background-color: var(--PRIMARY_1_1);
          opacity: 1;
        }

        &__info {
          ${flex('column', 'flex-start')}
        }
      }

      &__create-reservation {
        background-color: var(--BACKGROUND_1_1);
        position: absolute;
        ${elevate_xxl};
        border-radius: 8px;
        border: 2px solid var(--SHADE_1_9);

        z-index: 1;
        padding: ${theme.sizes.SIZE_16};

        &--left {
          top: 0;
          right: 100%;
          transform: translateX(-8px);
        }
        &--right {
          top: 0;
          left: 100%;
          transform: translateX(8px);
        }
      }
    }
  }
`;
