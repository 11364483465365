import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const CarParkElement = styled.div`
  ${flex('column', 'space-between')}
  border: 1px solid var(--SHADE_1_6);
  border-radius: ${theme.sizes.SIZE_6};
  padding: ${theme.sizes.SIZE_16};
  cursor: pointer;

  svg {
    color: var(--PRIMARY_1_1);
  }

  .carpark {
    &__title {
      font-size: ${theme.fontSizes.FONT_16};
      line-height: ${theme.fontSizes.FONT_18};
      font-weight: 600;
      color: var(--SHADE_1_1);
      margin-bottom: ${theme.sizes.SIZE_24};
      ${flex('row', 'space-between')}
      flex: 1;
    }

    &__details {
      ${flex('row')}
      gap: ${theme.sizes.SIZE_8};
      color: var(--SHADE_1_2);
      font-size: ${theme.fontSizes.FONT_12};
      margin-bottom: ${theme.sizes.SIZE_12};
      svg {
        ${size(theme.sizes.SIZE_16)}
      }
    }

    &__raise-barrier-btn {
      margin-top: ${theme.sizes.SIZE_8};
    }
  }
`;
