import React, { useEffect, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { getMsalConfig } from 'config/microsoftAuthConfig';
import MsalContext, { initialMsalInstance } from './MsalWrapper.context';

export const MsalWrapper = ({ children }: { children: React.ReactNode }) => {
  const [msalInstance, setMsalInstance] = useState(initialMsalInstance);

  const [updateCallback, setUpdateCallback] = useState<
    ((msalInstance: any) => void) | null
  >(null);

  const updateMsalInstance = async (
    clientId: string,
    tenantId: string,
    callback?: (msalInstance: any) => void
  ) => {
    const newInstance = new PublicClientApplication(
      getMsalConfig(clientId, tenantId)
    );

    await newInstance.initialize();

    // Ensure redirect promise is handled after changing the instance
    // This recreates msal instance callbacks!!!
    newInstance.handleRedirectPromise();

    setMsalInstance(newInstance);
    if (callback) {
      setUpdateCallback(() => callback);
    }
  };

  useEffect(() => {
    if (updateCallback) {
      updateCallback(msalInstance);
      setUpdateCallback(null);
    }
  }, [msalInstance, updateCallback]);

  return (
    <MsalContext.Provider value={{ msalInstance, updateMsalInstance }}>
      <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    </MsalContext.Provider>
  );
};

export default MsalWrapper;
