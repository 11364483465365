import {
  useContext,
  useState,
  useCallback,
  useMemo,
  FC,
  useRef,
  memo,
} from 'react';
import {
  Button,
  Checkbox,
  ModalProps,
  ModalRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';

import { AppContext, UserContext } from 'store';
import { apiCommunity } from 'modules';
import { createUrl } from 'utils';
import { useCallbackAsync, useQuery } from 'hooks';
import { APIResponseCustom, InviteCodes } from 'models';
import InvitePeopleCode from './components/InvitePeopleCode';
import ClipboardElement from '../../_molecules/ClipboardElement';

import * as Styled from './InvitePeopleModal.styles';

const simulateMailto = (mailtoAddress: string) => {
  const link = document.createElement('a');

  link.href = mailtoAddress;
  const event = new MouseEvent('click');
  link.dispatchEvent(event);
  link.remove();
};

export type InvitePeopleModalProps = {
  organisationId: number;
} & ModalProps;

const InvitePeopleModal: FC<InvitePeopleModalProps> = (
  props: InvitePeopleModalProps
): JSX.Element => {
  const { organisationId, triggerRef, ...rest } = props;

  const { t } = useTranslation();

  const { community } = useContext(UserContext);
  const { platform } = useContext(AppContext);

  const { showSnackBar, hideSnackBar } = useUtilities();

  const [magicLink, setMagicLink] = useState<{
    code: string;
    joincode?: string;
  }>({ code: '' });
  const [qrCodeURL, setQrCodeUrl] = useState('');

  const downloadSnackBarId = useRef<string>('');
  const [modal, modalRef] = useCallbackRef<ModalRef>();

  const mailToLink = useMemo(
    () =>
      `mailto:?subject=${t('send_email_link_subject')}&body=${t(
        'send_email_link_body',
        {
          community_link: encodeURIComponent(magicLink.code),
          community: community?.name,
        }
      )
        .replace(/\\r/gi, '%0D%0A%0D%0A')
        .replace(/&/gi, '%26')}`,
    [community, magicLink, t]
  );

  const closeModal = useCallback(() => {
    modal.close();
    triggerRef?.focus();
  }, [modal, triggerRef]);

  const magicCodeQuery = useCallback(
    async () => await apiCommunity.getMagicLink(organisationId),
    [organisationId]
  );

  const {
    data: fastCode,
    loading: loadingGet,
    setData: setFastCode,
  } = useQuery<APIResponseCustom<InviteCodes>, InviteCodes>({
    showSpinner: false,
    queryFn: magicCodeQuery,
    deps: [magicCodeQuery],
    onSuccess: ({ code, joincode }) => {
      setMagicLink({
        code: createUrl(`${window.location.origin}/@${code}`, {
          join: joincode,
          pid: platform?.id,
        }),
        joincode,
      });
    },
  });

  const [handleChangeJoinCode, loadingJoinCode] = useCallbackAsync({
    showSpinner: false,
    callback: async (join: boolean) => {
      const { joincode } = await apiCommunity.updateJoinCode(
        organisationId,
        join
      );

      setMagicLink({
        code: createUrl(`${window.location.origin}/@${fastCode.code}`, {
          join: joincode,
          pid: platform?.id,
        }),
        joincode,
      });
    },
  });

  const [updateMagicLink, loadingUpdate] = useCallbackAsync({
    showSpinner: false,
    callback: async (newCode: string) => {
      await apiCommunity.updateMagicLink(organisationId, newCode);

      setMagicLink(({ joincode }) => ({
        code: createUrl(`${window.location.origin}/@${newCode}`, {
          join: joincode,
          pid: platform?.id,
        }),
        joincode,
      }));

      setFastCode({ code: newCode });

      showSnackBar({
        actionButtonText: t('dismiss'),
        text: t('link_saved'),
        variant: 'success',
      });
    },
  });

  const [downloadPDF] = useCallbackAsync({
    showSpinner: true,
    callback: async () => {
      downloadSnackBarId.current = showSnackBar(
        {
          text: t('waiting_download'),
          variant: 'loading',
          loadingText: t('downloading'),
        },
        { constant: true }
      );

      const pdf = new jsPDF('portrait', 'pt', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      pdf.setFontSize(24);

      const img = new Image(40, 40);
      img.src = community?.image_url || '/assets/images/users-icon.png';

      const yImagePosition = 150;
      const xImagePosition = (pageWidth - 40) / 2;

      img.onload = () => {
        pdf.addImage(img, 'png', xImagePosition, yImagePosition, 40, 40);
      };

      const communityElement = document.querySelector(
        '.kinto-community-element'
      ) as HTMLElement;

      if (communityElement) {
        const prevFontSize = communityElement.style.fontSize;
        const prevJustify = communityElement.style.justifyContent;

        communityElement.style.fontSize = '24px';
        communityElement.style.justifyContent = 'center';

        const spanElement = communityElement.querySelector(
          'span'
        ) as HTMLElement;
        const prevSpanMargin = spanElement.style.marginRight;
        spanElement.style.marginRight = '16px';

        const { width: commWidth, height: commHeight } =
          communityElement.getBoundingClientRect();

        const canvas = await html2canvas(communityElement as HTMLElement, {
          width: commWidth,
          height: commHeight + 12,
        });

        const communityImgData = canvas.toDataURL('image/jpeg', 1.0);

        const headerHtmlEl = document.createElement('div');
        const { width: headerWidth, height: headerHeight } =
          communityElement.getBoundingClientRect();
        headerHtmlEl.innerHTML = `
          <div style="font-size:24px; text-align:center; width: 100%;">
            <p style="font-family: 'ToyotaType'">${t(
              'invite-people_pdf_title'
            )}</p>
          </div>
        `;
        document.body.appendChild(headerHtmlEl);

        const inviteTextCanvas = await html2canvas(headerHtmlEl);
        const imgData = inviteTextCanvas.toDataURL('image/png');

        const xTextPosition = (pageWidth - headerWidth) / 2;
        const yTextPosition = yImagePosition + 40;

        pdf.addImage(
          imgData,
          'PNG',
          xTextPosition,
          yTextPosition,
          headerWidth,
          headerHeight
        );

        const xNamePosition = (pageWidth - commWidth) / 2;
        const yNamePosition = yTextPosition + 40;

        pdf.addImage(
          communityImgData,
          'JPEG',
          xNamePosition,
          yNamePosition,
          commWidth,
          commHeight + 12
        );

        const qrCodeHeight = 160;
        const xQrCodePosition = (pageWidth - qrCodeHeight) / 2;
        const yQrCodePosition = yNamePosition + 100;

        pdf.addImage(
          qrCodeURL,
          'base64',
          xQrCodePosition,
          yQrCodePosition,
          qrCodeHeight,
          qrCodeHeight
        );

        // revert changes
        communityElement.style.fontSize = prevFontSize;
        communityElement.style.justifyContent = prevJustify;
        spanElement.style.marginRight = prevSpanMargin;
        document.body.removeChild(headerHtmlEl);

        pdf.save('invite-people.pdf');
      }
    },
    onFinish: () => {
      hideSnackBar(downloadSnackBarId.current);
    },
  });

  return (
    <Styled.InvitePeopleModal
      {...rest}
      ref={modalRef}
      className="invite-people-modal"
      loading={loadingGet || loadingUpdate}
      triggerRef={triggerRef}
      position="top"
      title={t('invite_people_to_community')}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t('invite_people_to_community'),
      })}
      footer={
        <>
          <Button variant="outline" onClick={() => simulateMailto(mailToLink)}>
            {t('invite-people_button_send_invitation_via_email')}
          </Button>
          <Button variant="primary" onClick={() => downloadPDF()}>
            {t('invite-people_button_download_code')}
          </Button>
        </>
      }
    >
      <p className="invite-people-modal__share-title">{t('share_link')}</p>

      <ClipboardElement
        link={magicLink.code}
        code={fastCode?.code}
        loading={loadingJoinCode}
        onCopy={closeModal}
        onUpdate={updateMagicLink}
      />

      <Checkbox
        checked={!!magicLink.joincode}
        disabled={loadingJoinCode}
        className="invite-people-modal__box"
        label={t('invite-people_button_preapprove_into_community')}
        onChange={handleChangeJoinCode}
      />

      <InvitePeopleCode
        link={magicLink.code}
        qrCodeUrl={qrCodeURL}
        onGenerateCode={setQrCodeUrl}
      />
    </Styled.InvitePeopleModal>
  );
};

export default memo(InvitePeopleModal);
