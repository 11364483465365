import { theme } from '@faxi/web-component-library';
import { fontSize, mobile, pxToRem } from '@faxi/web-css-utilities';

import styled, { css } from 'styled-components';

export type NetworkErrorOverlayProps = {
  $phase: '1' | '2' | '3';
};

export const Container = styled.div<NetworkErrorOverlayProps>`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .network-error {
    &__top-message {
      background-color: ${(props: NetworkErrorOverlayProps) =>
        props.$phase === '1'
          ? 'var(--ALERT_ERROR_1_1)'
          : props.$phase === '2'
          ? 'var(--PRIMARY_1_1)'
          : 'var(--ALERT_SUCCESS_1_1)'};
      height: ${pxToRem(theme.sizes.SIZE_48)};
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--BACKGROUND_1_1);
      font-weight: 600;
      ${fontSize(theme.fontSizes.FONT_12)};
      width: ${pxToRem(theme.sizes.SIZE_456)};
      padding: 0 ${theme.sizes.SIZE_20};
      margin-top: ${theme.sizes.SIZE_40};
      border-radius: ${theme.sizes.SIZE_8};

      ${mobile(
        css`
          margin-top: ${theme.sizes.SIZE_32};
          width: calc(100% - ${pxToRem('60px')});
        `
      )};

      &__phase-2 {
        display: flex;
        flex-direction: row;

        &__text {
          margin-right: ${theme.sizes.SIZE_16};
        }
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: ${theme.sizes.SIZE_160};

      &__icon {
        color: var(--SECONDARY_1_1);
      }

      &__text {
        color: var(--PRIMARY_1_1);
        ${fontSize(theme.fontSizes.FONT_24)};
        margin-top: ${theme.sizes.SIZE_20};
        font-weight: 500;
      }
    }
  }
`;
