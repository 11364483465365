import env from 'env';
import { Configuration } from '@azure/msal-browser';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

// Default MSAL Config
export const getMsalConfig = (
  clientId?: string,
  tenantId?: string
): Configuration => ({
  auth: {
    clientId:
      clientId ||
      localStorage.getItem('sso_client_id') ||
      env.VITE_APP_MICROSOFT_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${
      tenantId || localStorage.getItem('sso_tenant_id') || 'common'
    }`,
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: `${window.location.origin}/login-redirection`, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
});

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['User.Read', 'email'],
};
