import { useCallback, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Icon,
  RadioGroup,
  useUtilities,
} from '@faxi/web-component-library';

import { apiNetPromoterScore } from 'modules';
import { NetPromoterScoreContext } from 'store';

import * as Styled from './NetPromoterScorePopup.styles';

const radioGroupOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el) => ({
  label: `${el}`,
  value: `${el}`,
}));

const NetPromoterScorePopup = () => {
  const {
    netPromoterScore,
    setDiscardNetPromoterScore,
    getNetPromoterScore,
    setNetPromoterScore,
  } = useContext(NetPromoterScoreContext);

  const { t } = useTranslation();

  const [mark, setMark] = useState<string>();

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const handleAddMark = useCallback(async () => {
    if (mark) {
      showOverlay('#root');
      try {
        const { data } = await apiNetPromoterScore.addNetPromoterScoreAnswer(
          netPromoterScore?.survey_id!,
          +mark
        );

        setNetPromoterScore(null);

        if (data) {
          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t('nps_survey-title_thank_you_dialog'),
            variant: 'success',
          });
          getNetPromoterScore();
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('#root');
      }
    }
  }, [
    getNetPromoterScore,
    hideOverlay,
    mark,
    netPromoterScore?.survey_id,
    setNetPromoterScore,
    showOverlay,
    showSnackBar,
    t,
  ]);

  const extendChildren = (
    <Styled.NetPromoterScorePopup>
      <Button
        className="kinto-net-promoter-score-popup__close-btn"
        icon={<Icon name="xmark" />}
        variant="ghost"
        onClick={() => {
          localStorage.setItem('discardNetPromoterScore', 'true');
          setDiscardNetPromoterScore(true);
        }}
      />
      <div className="kinto-net-promoter-score-popup__question">
        {t('nps_survey-title_app_recommendation')}
      </div>
      <RadioGroup
        variant="chip"
        name="rate"
        orientation="row"
        options={radioGroupOptions}
        value={mark}
        onChange={(value) => {
          setMark(value);
        }}
      />

      <div className="kinto-net-promoter-score-popup__mark-lbl-container">
        <div>{t('nps_survey-label_not_likely')}</div>
        <div>{t('nps_survey-label_very_likely')}</div>
      </div>

      {mark && (
        <Button
          className="kinto-net-promoter-score-popup__action-btn"
          onClick={handleAddMark}
        >
          {t('done')}
        </Button>
      )}
    </Styled.NetPromoterScorePopup>
  );

  return ReactDOM.createPortal(extendChildren, document.body);
};

export default NetPromoterScorePopup;
