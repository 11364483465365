import { CreateCarparkApiParams } from 'models';
import httpClient from '../httpClient';

const openCarParkBarrier = () => httpClient.post(`notifications/sms`);

const createParking = (data: CreateCarparkApiParams) =>
  httpClient.post(`parkings`, data);

const getCarParkById = (parkingId: string) =>
  httpClient.get(`parkings/${parkingId}`);

const getAllCarParks = (organisation_id: number) =>
  httpClient.get(`parkings`, { params: { organisation_id } });

type CreateReservationArgs = {
  parking_id: string;
  spot_id: number;
  status: string;
  start_date: string;
  end_date: string;
  user_id?: string;
  note?: string;
};

const createReservation = (data: CreateReservationArgs) =>
  httpClient.post(`reservations`, data);

const getReservations = (
  organisation_id: number,
  parking_id: string,
  spot_id?: string,
  user_id?: string
) =>
  httpClient.get(`reservations`, {
    params: { organisation_id, parking_id, spot_id, user_id },
  });

const deleteReservation = (reservationId: number) =>
  httpClient.delete(`reservations/${reservationId}`);

const editReservation = (
  reservationId: number,
  title: string,
  description: string
) =>
  httpClient.put(`reservations/${reservationId}`, { title, note: description });

export default {
  openCarParkBarrier,
  createParking,
  getCarParkById,
  getAllCarParks,
  createReservation,
  getReservations,
  deleteReservation,
  editReservation,
};
