import { theme } from '@faxi/web-component-library';
import { size } from '@faxi/web-css-utilities';
import { styled } from 'styled-components';

export const CreateReservationDropdown = styled.div`
  color: var(--SHADE_1_1);
  min-width: ${theme.sizes.SIZE_440};

  .reservation-preview {
    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-weight: 600;
      font-size: ${theme.fontSizes.FONT_16};

      &__actions {
        display: flex;
        gap: ${theme.sizes.SIZE_16};
        margin-left: auto;
      }

      > span {
        margin-bottom: ${theme.sizes.SIZE_32};
      }
    }

    &__status {
      display: flex;
      gap: ${theme.sizes.SIZE_16};
      margin-bottom: ${theme.sizes.SIZE_16};
      font-size: ${theme.fontSizes.FONT_14};

      svg {
        color: var(--PRIMARY_1_1);
        ${size(theme.sizes.SIZE_16)};
      }

      &__label {
        background-color: var(--PRIMARY_1_1);
        color: var(--BACKGROUND_1_1);
        padding: ${theme.sizes.SIZE_6} ${theme.sizes.SIZE_10};
        border-radius: ${theme.sizes.SIZE_8};
      }
    }

    &__date {
      font-size: ${theme.fontSizes.FONT_16};
      margin-bottom: ${theme.sizes.SIZE_24};

      > span {
        font-weight: 600;
        margin-right: ${theme.sizes.SIZE_6};
      }
    }

    &__note {
      &__label {
        font-size: ${theme.fontSizes.FONT_14};
        display: block;
        margin-bottom: ${theme.sizes.SIZE_4};
      }
      &__text {
        color: var(--SHADE_1_2);
        display: block;
      }
      margin-bottom: ${theme.sizes.SIZE_24};
    }
  }
`;
