import axios, { AxiosResponse } from 'axios';
import { LoginPlatform, RefreshTokenResponse } from 'models';
import httpClient from '../httpClient';
import env from 'env';

const login = async (formData: FormData) =>
  httpClient
    .post('session', formData, { params: { version: 2 } })
    .then((res) => res.data);

const invalidateSession = (sid: string) =>
  httpClient.post('session', { sid }, { params: { _method: 'PUT' } });

const refreshToken = (
  refresh_token: string
): Promise<AxiosResponse<RefreshTokenResponse>> => {
  const formData = new FormData();
  formData.append('refresh_token', refresh_token);

  return httpClient.post<RefreshTokenResponse>('auth/refresh', formData, {
    isRefresh: true,
  } as any);
};

const socialLogin = async (provider: LoginPlatform, token: string) => {
  const formData = new FormData();
  formData.append('token', token);
  formData.append('provider', provider);
  formData.append('web', '1');

  return httpClient.post(`/social`, formData).then((res) => res.data);
};

const socialRegister = async (
  provider: LoginPlatform,
  token: string,
  firstName: string,
  lastName: string,
  platformId: string
) => {
  const formData = new FormData();

  formData.append('token', token);
  formData.append('provider', provider);
  formData.append('idpltf', platformId);
  formData.append('firstname', firstName);
  lastName && formData.append('name', lastName);
  formData.append('orig', 'web');
  formData.append('web', '1');
  formData.append(
    'consent',
    JSON.stringify([
      { key: 't&c', val: 'Y' },
      { key: 'prp', val: 'Y' },
    ])
  );
  return httpClient.post(`/social`, formData).then((res) => res.data);
};

const ssoLogin = (email: string) => {
  const formData = new FormData();
  formData.append('email', email);

  //bacause we dont have .env.staging setup we must detect it manually
  const loginServis = window.location.href.includes('staging')
    ? 'https://regions.kinto-join-io.staging.kjforge.xyz/api'
    : env.VITE_LOGIN_SERVICE_URL;

  return axios.post(`${loginServis}/social/provider`, formData);
};

export default {
  login,
  socialLogin,
  invalidateSession,
  refreshToken,
  socialRegister,
  ssoLogin,
};
